import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
  MobilePopoverCard,
  PickerType,
} from "halifax";
import React from "react";
import { RouteComponentProps } from "react-router";
import {
  IIdLodgings,
  LAUNCHED_MOBILE_CALENDAR,
  LaunchedMobileCalendarProperties,
} from "redmond";

import { MobileCalendarPickerModalConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
import {
  PATH_HOME_SEARCH,
  PATH_HOTELS_AVAILABILITY,
} from "../../../../utils/paths";
import { trackEvent } from "../../../../api/v0/trackEvent";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
  PackagesSearchButton,
} from "../../../search/components/PackagesSearchControl/components";
import { transformToStringifiedAvailabilityQuery } from "../../utils/queryStringHelpers";
export interface IMobileCalendarPickerModalProps
  extends MobileCalendarPickerModalConnectorProps,
    RouteComponentProps {
  focusedMonthIndex?: number;
  openCalendarModal: boolean;
  setOpenCalendarModal: (val: boolean) => void;
  handleIncompleteSearch?: (val: boolean) => void;
  searchOnContinue?: boolean;
  headerText?: string;
  fullScreen?: boolean;
  onComplete?: (from: Date | null, localReturnDate: Date | null) => void;
}

export const MobileCalendarPickerModal = (
  props: IMobileCalendarPickerModalProps
) => {
  const {
    departureDate,
    returnDate,
    searchLocation,
    setDepartureDate,
    setReturnDate,
    focusedMonthIndex,
    openCalendarModal,
    setOpenCalendarModal,
    handleIncompleteSearch,
    searchOnContinue = false,
    history,
    headerText,
    fullScreen = false,
    onComplete,
    travelers,
    origin,
    stopsOptions,
  } = props;

  const [localDepartureDate, setLocalDepartureDate] =
    React.useState<Date | null>(null);
  const [localReturnDate, setLocalReturnDate] = React.useState<Date | null>(
    null
  );

  React.useEffect(() => {
    setLocalDepartureDate(departureDate);
  }, [departureDate]);

  React.useEffect(() => {
    setLocalReturnDate(returnDate);
  }, [returnDate]);

  React.useEffect(() => {
    if (window.location.pathname === PATH_HOME_SEARCH) {
      const properties: LaunchedMobileCalendarProperties = {
        funnel: "other",
        url: window.location.pathname,
      };
      trackEvent({ eventName: LAUNCHED_MOBILE_CALENDAR, properties });
    }
  }, []);

  const isReadyToSearch =
    !!origin &&
    !!searchLocation &&
    !!localDepartureDate &&
    !!localReturnDate &&
    !!travelers.adultsCount;

  const handleContinue = () => {
    setDepartureDate(localDepartureDate);
    setReturnDate(localReturnDate);
    setOpenCalendarModal(false);
    isReadyToSearch
      ? history.push(
          `${PATH_HOTELS_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
            origin?.id?.code?.code,
            (searchLocation?.id as IIdLodgings).lodgingSelection.searchTerm,
            localDepartureDate,
            localReturnDate,
            travelers.adultsCount,
            travelers.children,
            travelers.infants,
            stopsOptions
          )}`
        )
      : handleIncompleteSearch && handleIncompleteSearch(true);
    onComplete?.(localDepartureDate, localReturnDate);
  };

  const handleClose = () => {
    !searchOnContinue && setDepartureDate(localDepartureDate);
    !searchOnContinue && setReturnDate(localReturnDate);
    searchOnContinue && setLocalDepartureDate(departureDate);
    searchOnContinue && setLocalReturnDate(returnDate);
    setOpenCalendarModal(false);
  };

  const MobileSearchPopoverHeader = () => {
    return (
      <Box className="modal-header-container">
        <Typography className="header-title">
          {headerText || textConstants.CHOOSE_DATES}
        </Typography>
        <ActionLink
          className="modal-close-button"
          onClick={handleClose}
          content={<CloseButtonIcon className="close-button-icon" />}
          label="Close"
          showTappableArea
        />
      </Box>
    );
  };
  const DatePickerHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <MobilePopoverCard
      open={openCalendarModal}
      className={"mobile-hotel-search-calendar-popup"}
      onClose={handleClose}
      headerElement={<MobileSearchPopoverHeader />}
      fullScreen={!!fullScreen}
    >
      <Box className="hotel-mobile-calendar-picker-root redesign-v2">
        <MonthAndDatePicker
          className="b2b"
          viewType={MonthAndDatePickerType.Column}
          focusedMonthIndex={focusedMonthIndex}
          setStartDate={setLocalDepartureDate}
          setEndDate={setLocalReturnDate}
          startDate={localDepartureDate}
          endDate={localReturnDate}
          header={
            <Box className="hotel-mobile-calendar-header">
              <DatePickerHeader
                label={textConstants.CHECK_IN}
                value={
                  localDepartureDate
                    ? dayjs(localDepartureDate).format("ddd D, MMM")
                    : undefined
                }
              />
              <DatePickerHeader
                label={textConstants.CHECK_OUT}
                value={
                  localReturnDate
                    ? dayjs(localReturnDate).format("ddd D, MMM")
                    : undefined
                }
              />
            </Box>
          }
          pickerType={PickerType.RANGE}
        />
        {localDepartureDate && localReturnDate && (
          <PackagesSearchButton
            className={clsx("mobile-calendar-picker-search-button", "b2b")}
            message={textConstants.SEARCH_HOTELS}
            onClick={handleContinue}
            departure={localDepartureDate}
            return={localReturnDate}
            enabled={localDepartureDate && localReturnDate ? true : false}
          />
        )}
      </Box>
    </MobilePopoverCard>
  );
};
