import React, { useEffect, useState } from "react";
import { DesktopPopupModal, PickerType } from "halifax";
import { Button } from "@material-ui/core";
import * as textConstants from "./textConstants";
import "./styles.scss";
import clsx from "clsx";
import { TripCategory } from "redmond";
import { ISetDepartureDate, ISetReturnDate } from "../../../../actions/actions";
import { MonthAndDatePicker, MonthAndDatePickerType } from "../";

export interface IDesktopCalendarPickerProps {
  isOpen: boolean;
  closePopup: () => void;
  tripCategory?: TripCategory;
  departureProp?: Date | null;
  returnProp?: Date | null;
  onClickDone?: () => void;
  saveDatesOnClose?: boolean;
  departureDate: Date | null;
  returnDate: Date | null;
  setReturnDate: (date: Date | null) => ISetReturnDate | void;
  setDepartureDate: (date: Date | null) => ISetDepartureDate | void;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
  headerTitle?: JSX.Element | string;
}

export const DesktopCalendarPicker = (props: IDesktopCalendarPickerProps) => {
  const {
    isOpen,
    closePopup,
    departureDate,
    returnDate,
    setDepartureDate,
    setReturnDate,
    departureProp,
    returnProp,
    tripCategory,
    onClickDone,
    saveDatesOnClose,
    headerTitle,
    minAllowedDate,
    maxAllowedDate,
  } = props;

  const calendarHeaderTitle =
    headerTitle ||
    (tripCategory === TripCategory.ROUND_TRIP
      ? textConstants.DESKTOP_CALENDAR_TITLE_ROUND_TRIP
      : textConstants.DESKTOP_CALENDAR_TITLE_ONE_WAY);

  const [dep, setDep] = useState<Date | null>(null);
  const [ret, setRet] = useState<Date | null>(null);

  useEffect(() => {
    setDep(departureDate);
  }, [departureDate]);

  useEffect(() => {
    setRet(returnDate);
  }, [returnDate]);

  useEffect(() => {
    departureProp && setDep(departureProp);
  }, [departureProp]);

  useEffect(() => {
    returnProp && setRet(returnProp);
  }, [returnProp]);

  const handleClickDone = () => {
    closePopup();
    setDepartureDate(dep);
    setReturnDate(ret);
    onClickDone && onClickDone();
  };

  const handleClose = () => {
    closePopup();
    if (!saveDatesOnClose) {
      setDep(departureDate);
      setRet(returnDate);
    }
  };

  return (
    <DesktopPopupModal
      open={isOpen}
      onClose={handleClose}
      className={clsx(
        "desktop-calendar-picker-popup-root",
        "desktop-flight-calendar-picker-popup-root",
        "flights-module"
      )}
      contentClassName="desktop-calendar-picker-wrapper"
      invisibleBackdrop={false}
    >
      <MonthAndDatePicker
        pickerType={
          tripCategory === TripCategory.ROUND_TRIP
            ? PickerType.RANGE
            : PickerType.DAY
        }
        viewType={MonthAndDatePickerType.Horizontal}
        startDate={dep}
        endDate={ret}
        setStartDate={(val: Date | null) => {
          setDep(val);
        }}
        setEndDate={(val: Date | null) => {
          setRet(val);
        }}
        minAllowedDate={minAllowedDate}
        maxAllowedDate={maxAllowedDate}
        header={calendarHeaderTitle}
        className="b2b-flights"
      />
      <Button
        onClick={handleClickDone}
        disabled={!ret || !dep}
        className="select-dates-button"
        variant="contained"
      >
        {textConstants.DONE_BUTTON_TEXT}
      </Button>
    </DesktopPopupModal>
  );
};
