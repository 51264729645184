import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../../../reducers/types";

import { fetchTravelWalletDetails } from "../../../travel-wallet/actions/actions";
import { FlightList } from "./component";
import {
  fetchPackagesFlightShop,
  fetchPackagesTripDetails,
  setPackagesChosenOutgoingSlice,
  setPackagesChosenReturnSlice,
  setPackagesFlightShopProgress,
} from "../../actions/actions";
import { getRecommendedFlights } from "../../../availability/reducer";
import {
  getAirShopSummary,
  getFareTripDetailsById,
  getFlightShopCallResults,
  flightsSelector,
  getIsFlightShopLoading,
  getIsOutgoing,
  getIsTripDetailsLoading,
  getOutboundFlightsToRender,
  getPackagesByReturnFareSlice,
  getParsedPackagesByOutboundFareSlice,
  getRecommendedOutgoingFlight,
  getRecommendedReturnFlight,
  getReturnFlightsToRender,
  getSelectedTrip,
  getSortedAndFilteredFlights,
  getMaxPriceFilter,
} from "../../reducer";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import { getDestination, getOrigin } from "../../../search/reducer";
import { RouteComponentProps } from "react-router-dom";

const mapStateToProps = (state: IStoreState) => {
  return {
    recommendedFlights: getRecommendedFlights(state),
    flightShopCallResults: getFlightShopCallResults(state),
    isFlightShopLoading: getIsFlightShopLoading(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    airShopSummary: getAirShopSummary(state),
    flights: flightsSelector(state),
    packagesByOutboundFareSlice: getParsedPackagesByOutboundFareSlice(state),
    packagesByReturnFareSlice: getPackagesByReturnFareSlice(state),
    origin: getOrigin(state),
    destination: getDestination(state),
    isTripDetailsLoading: getIsTripDetailsLoading(state),
    tripDetailsById: getFareTripDetailsById(state),
    isOutgoing: getIsOutgoing(state),
    outboundFlights: getOutboundFlightsToRender(state),
    returnFlights: getReturnFlightsToRender(state),
    selectedTrip: getSelectedTrip(state),
    sortedFlights: getSortedAndFilteredFlights(state),
    maxPriceFilter: getMaxPriceFilter(state),
    outgoingRecommendedFlight: getRecommendedOutgoingFlight(state),
    returnRecommendedFlight: getRecommendedReturnFlight(state),
  };
};

export const mapDispatchToProps = {
  fetchTravelWalletDetails: fetchTravelWalletDetails,
  fetchPackagesFlightShop: fetchPackagesFlightShop,
  fetchPackagesTripDetails: fetchPackagesTripDetails,
  setPackagesChosenOutgoingSlice: setPackagesChosenOutgoingSlice,
  setPackagesChosenReturnSlice: setPackagesChosenReturnSlice,
  setPackagesFlightShopProgress: setPackagesFlightShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightListConnectorProps = ConnectedProps<typeof connector>;

export interface IFlightListProps
  extends FlightListConnectorProps,
    RouteComponentProps {}

export const ConnectedFlightList = withRouter(connector(FlightList));
