import React from "react";
import "./styles.scss";
import { RouteComponentProps } from "react-router-dom";
import { ActionButton, HotelPriceBreakdown } from "halifax";
import { PackagesPriceBreakdownConnectorProps } from "./container";
import {
  CardPaymentSelectors,
  getParentState,
  ParentState,
  useCheckoutState,
  useCheckoutStateSelector,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import {
  getPriceBreakdownPricingLineItems,
  getPriceBreakdownSummaryLineItems,
  getPriceBreakdownTotalLineItems,
} from "../../state/selectors/common";
import { Box, Typography } from "@material-ui/core";
import { CHECKOUT_BREAKDOWN_HEADING } from "./textConstants";
import { CONFIRM_AND_BOOK_CTA_TEXT } from "../textConstants";

export interface PackagesPriceBreakdownProps
  extends PackagesPriceBreakdownConnectorProps,
    RouteComponentProps {}

export const PackagesPriceBreakdown = ({}: PackagesPriceBreakdownProps) => {
  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;

  const pricingLineItems = useCheckoutStateSelector(
    getPriceBreakdownPricingLineItems
  );
  const totalLineItems = useCheckoutStateSelector(
    getPriceBreakdownTotalLineItems
  );
  const summaryLineItems = useCheckoutStateSelector(
    getPriceBreakdownSummaryLineItems
  );
  const isCostCoveredBySelectedPaymentMethods = useCheckoutStateSelector(
    CardPaymentSelectors.getIsCostCoveredBySelectedPaymentMethods
  );

  const handleContinue = () => {
    send(Event.NEXT);
  };

  return (
    <Box className="packages-price-breakdown-wrapper">
      <Typography variant="h3" className="price-breakdown-heading">
        {CHECKOUT_BREAKDOWN_HEADING}
      </Typography>
      <HotelPriceBreakdown
        pricingItems={pricingLineItems}
        totalItems={totalLineItems}
        summaryItems={summaryLineItems}
      />
      <ActionButton
        onClick={handleContinue}
        message={CONFIRM_AND_BOOK_CTA_TEXT}
        className="packages-continue-button book"
        disabled={
          !isCostCoveredBySelectedPaymentMethods ||
          parentState !== ParentState.cardPayment
        }
      />
    </Box>
  );
};
