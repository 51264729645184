import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { PackagesRewardsAndPaymentConnectorProps } from "./container";
import {
  CardPaymentSelectors,
  cartQuoteSelectors,
  getNestedChildState,
  getParentState,
  ParentState,
  PaymentInformationChildState,
  RewardsAndPayment,
  RewardsPaymentEventTypes,
  RewardsPaymentSelectors,
  useCheckoutState,
  useCheckoutStateSelector,
  WalletEventTypes,
  WalletSelectors,
} from "@capone/checkout";
import { config } from "../../../../api/config";
import { PackagesMachineContext } from "../../state/types";
import { Event, TEvent } from "../../state/events";
import {
  ActionButton,
  B2BSpinner,
  GenericInfoPopup,
  Icon,
  IconName,
  LoadingPopup,
  PoweredByHopper,
  TEST_CARD_LAST_FOURS,
  useDeviceTypes,
} from "halifax";
import {
  TokenizeCardErrors,
  TravelWalletCredit,
  TravelWalletOffer,
} from "redmond";
import { getErrorModalProps } from "./utils";
import clsx from "clsx";
import { CARD_VERIFY_TEXT } from "./textConstants";
import "./styles.scss";
import { ClientContext } from "../../../../App";
import { CONFIRM_AND_BOOK_CTA_TEXT } from "../textConstants";

export interface IPackagesRewardsAndPaymentProps
  extends RouteComponentProps,
    PackagesRewardsAndPaymentConnectorProps {}

export const PackagesRewardsAndPayment = ({
  rewardsAccounts,
}: IPackagesRewardsAndPaymentProps) => {
  const { sessionInfo } = React.useContext(ClientContext);

  const { matchesMobile } = useDeviceTypes();

  const [tokenizeErrors, setTokenizeErrors] = React.useState<
    TokenizeCardErrors[]
  >([]);
  const [invalidCapOneCardError, setInvalidCapOneCardError] =
    React.useState(false);

  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const parentState = getParentState(state.value) as ParentState;
  const nestedChildState = getNestedChildState(state.value);

  /* selectors */

  const paymentMethods = useCheckoutStateSelector(
    CardPaymentSelectors.getPaymentMethods
  );

  const offers = useCheckoutStateSelector(WalletSelectors.getOffers);
  const credits = useCheckoutStateSelector(WalletSelectors.getCredits);
  const offerToApply = useCheckoutStateSelector(
    WalletSelectors.getSelectedOffer
  );
  const creditsToApply = useCheckoutStateSelector(
    WalletSelectors.getTravelWalletCreditToApply
  );
  const isTravelCreditPaymentOnly = useCheckoutStateSelector(
    WalletSelectors.getIsTravelCreditPaymentOnly
  );
  const isTravelWalletOfferPaymentOnly = useCheckoutStateSelector(
    WalletSelectors.getIsTravelWalletOfferPaymentOnly
  );
  const isStackedTravelWalletPaymentOnly = useCheckoutStateSelector(
    WalletSelectors.getIsStackedTravelWalletPaymentOnly
  );
  const creditTransactionHistory = useCheckoutStateSelector(
    WalletSelectors.getCreditTransactionHistory
  );
  const maxApplicableTravelWalletCredit = useCheckoutStateSelector(
    WalletSelectors.getMaxApplicableCreditAmount
  );

  const paymentMethodsLoading = useCheckoutStateSelector(
    CardPaymentSelectors.getIsPaymentLoading
  );
  const selectedPaymentMethodId = useCheckoutStateSelector(
    CardPaymentSelectors.getSelectedPaymentMethodId
  );
  const isPaymentError = useCheckoutStateSelector(
    CardPaymentSelectors.getIsPaymentError
  );
  const addPaymentMethodModalOpen = useCheckoutStateSelector(
    CardPaymentSelectors.getOpenAddPaymentMethodModal
  );

  const selectedRewardsAccountId = useCheckoutStateSelector(
    RewardsPaymentSelectors.getSelectedAccountId
  );
  const cartBreakdownBalance = useCheckoutStateSelector(
    cartQuoteSelectors.getCartBreakdownBalance
  );
  const rewardsPaymentInFiatCurrency = useCheckoutStateSelector(
    RewardsPaymentSelectors.getRewardsFiatAmountToApply
  );
  const rewardsPaymentInRewardsCurrency = useCheckoutStateSelector(
    RewardsPaymentSelectors.getRewardsAmountToApply
  );
  const isCreditCardPaymentRequired = useCheckoutStateSelector(
    CardPaymentSelectors.getIsCreditCardPaymentRequired
  );
  // const totalCardPaymentRequired = useCheckoutStateSelector(
  //   CardPaymentSelectors.getTotalCardPaymentRequired
  // );
  const isCostCoveredBySelectedPaymentMethods = useCheckoutStateSelector(
    CardPaymentSelectors.getIsCostCoveredBySelectedPaymentMethods
  );
  const earnValueByRewardsAccountId = useCheckoutStateSelector(
    RewardsPaymentSelectors.getEarnValueByRewardsAccount
  );
  const rewardsPaymentAllowed = useCheckoutStateSelector(
    RewardsPaymentSelectors.getRewardsPaymentAllowed
  );

  /* action callbacks */

  const verifyPaymentMethod = (token: string) =>
    send({ type: Event.VERIFY_PAYMENT_METHOD, token });

  const deletePaymentMethod = (paymentId: string) =>
    send({ type: Event.DELETE_PAYMENT_METHOD, paymentMethod: { paymentId } });

  const setSelectedPaymentMethodId = (
    paymentMethodId: string,
    rewardsAccountId?: string
  ) => {
    send({
      type: Event.SET_SELECTED_PAYMENT_METHOD_ID,
      paymentMethod: { paymentId: paymentMethodId, rewardsAccountId },
    });
  };

  const handlePaymentMethodAdd = (token: string, last4: string) => {
    const account = rewardsAccounts.find(
      (account) =>
        account.lastFour === last4 ||
        account.lastFourVirtualCardNumbers?.includes(last4)
    );
    let matchingRewardsAccount = account;

    const isTestCard =
      window.__mclean_env__.ENV !== "production" &&
      TEST_CARD_LAST_FOURS.includes(last4);
    if (isTestCard) {
      matchingRewardsAccount = rewardsAccounts[0];
    }

    if (!!matchingRewardsAccount || isTestCard) {
      verifyPaymentMethod(token);
      setSelectedPaymentMethodId(token);
    } else {
      send(Event.CLOSE_PAYMENT_FORM);
      setInvalidCapOneCardError(true);
    }
  };

  const handleClearErrors = () => {
    send(Event.CLEAR_PAYMENT_ERROR);
    setTokenizeErrors([]);
    setInvalidCapOneCardError(false);
  };

  const handleClickAddPaymentMethod = () => send(Event.OPEN_PAYMENT_FORM);

  const handleCloseAddPaymentMethod = () => send(Event.CLOSE_PAYMENT_FORM);

  const setSelectedRewardsAccountId = (accountId?: string) =>
    send({
      type: RewardsPaymentEventTypes.SET_SELECTED_REWARDS_ACCOUNT_ID,
      selectedAccountId: accountId,
    });

  const setRewardsPaymentAmount = (amount: number) =>
    send({
      type: RewardsPaymentEventTypes.SET_REWARDS_AMOUNT_TO_PAY,
      rewardsAmountToPay: amount,
    });

  const setOfferToApply = (offer: TravelWalletOffer) =>
    offer
      ? send({ type: WalletEventTypes.SET_SELECTED_OFFER, offerId: offer.id })
      : send(WalletEventTypes.REMOVE_SELECTED_OFFER);

  const setCreditAmountToApply = (credits: TravelWalletCredit) =>
    send({
      type: WalletEventTypes.SET_CREDIT_AMOUNT_TO_APPLY,
      amount: credits?.amount?.amount || 0,
    });

  const handleContinue = () => {
    send(Event.NEXT);
  };

  const errorModalOpen =
    !!tokenizeErrors.length || invalidCapOneCardError || isPaymentError;

  const errorModalProps = getErrorModalProps(
    tokenizeErrors,
    invalidCapOneCardError,
    handleClearErrors
  );

  // TODO: update when productToEarn supports packages
  // React.useEffect(() => {
  //   if (selectedRewardsAccountId && totalCardPaymentRequired) {
  //     // TODO: move to state machine service?
  //     productToEarn({
  //       travelProduct: TravelProductEnum.Packages,
  //       account: selectedRewardsAccountId,
  //       amountUsd: totalCardPaymentRequired,
  //     })
  //       .then((res) => {
  //         send({
  //           type: RewardsPaymentEventTypes.SET_EARN_BY_REWARDS_ACCOUNT_ID,
  //           accountReferenceId: selectedRewardsAccountId,
  //           earnResponse: res,
  //         });
  //       })
  //       .catch((e) =>
  //         console.warn(
  //           `Failed to fetch earn for account ${selectedRewardsAccountId}: ${e}`
  //         )
  //       );
  //   }
  // }, [selectedRewardsAccountId, totalCardPaymentRequired]);

  return (
    <>
      <RewardsAndPayment
        rewardsAccounts={rewardsAccounts}
        paymentMethods={paymentMethods}
        loadingPaymentMethods={paymentMethodsLoading}
        spreedlyEnvironmentKey={config.spreedlyEnvironmentKey}
        selectedPaymentMethodId={selectedPaymentMethodId}
        verifyingPaymentMethod={
          nestedChildState === PaymentInformationChildState.verify
        }
        isMobile={matchesMobile}
        onRemovePaymentMethod={deletePaymentMethod}
        onSelectPaymentMethod={setSelectedPaymentMethodId}
        onAddPaymentMethod={handlePaymentMethodAdd}
        onAddPaymentMethodError={setTokenizeErrors}
        onClickAddPaymentMethod={handleClickAddPaymentMethod}
        closePaymentModal={handleCloseAddPaymentMethod}
        addPaymentModalOpen={addPaymentMethodModalOpen}
        selectedRewardsAccountId={selectedRewardsAccountId}
        setSelectedRewardsAccountId={setSelectedRewardsAccountId}
        total={
          cartBreakdownBalance
            ? {
                ...cartBreakdownBalance,
                rewards: cartBreakdownBalance.accountSpecific,
              }
            : undefined
        }
        rewardsPaymentInFiatCurrency={rewardsPaymentInFiatCurrency}
        rewardsPaymentInRewardsCurrency={rewardsPaymentInRewardsCurrency}
        setRewardsPaymentAmount={setRewardsPaymentAmount}
        isCreditCardPaymentRequired={isCreditCardPaymentRequired}
        offers={offers}
        credits={credits}
        offerToApply={offerToApply}
        creditsToApply={creditsToApply}
        isTravelCreditPaymentOnly={isTravelCreditPaymentOnly}
        isTravelWalletOfferPaymentOnly={isTravelWalletOfferPaymentOnly}
        isStackedTravelWalletPaymentOnly={isStackedTravelWalletPaymentOnly}
        firstName={sessionInfo?.userInfo.firstName || ""}
        creditTransactionHistory={creditTransactionHistory}
        setOfferToApply={setOfferToApply}
        setCreditToApply={setCreditAmountToApply}
        maxApplicableTravelWalletCredit={maxApplicableTravelWalletCredit}
        cardPaymentDisabled={!isCreditCardPaymentRequired}
        earnValueByRewardsAccountId={earnValueByRewardsAccountId}
        rewardsDisabled={!rewardsPaymentAllowed}
      />
      <GenericInfoPopup
        open={errorModalOpen}
        image={<Icon className="error-icon" name={IconName.ErrorState} />}
        {...errorModalProps}
      />
      <LoadingPopup
        classes={[
          clsx("packages-book-validating-card-modal", {
            mobile: matchesMobile,
          }),
        ]}
        indicatorSize={"small"}
        indicator={B2BSpinner}
        open={nestedChildState === PaymentInformationChildState.verify}
        popupSize={"small"}
        message={CARD_VERIFY_TEXT}
        footer={PoweredByHopper}
        textAlign={"center"}
      />
      <ActionButton
        onClick={handleContinue}
        message={CONFIRM_AND_BOOK_CTA_TEXT}
        className="packages-continue-button book"
        disabled={
          !isCostCoveredBySelectedPaymentMethods ||
          parentState !== ParentState.cardPayment
        }
      />
    </>
  );
};
