import React, { useContext } from "react";
import {
  B2BSpinner,
  TravelerSelectWorkflow,
  LoadingIndicator,
  TravelerSelectStep,
  useDeviceTypes,
  getDateTimeWithFormat,
} from "halifax";
import {
  IPerson,
  PersonId,
  ADD_FREQUENT_FLYER,
  CLICKED_ADD_TRAVELER,
  ClickedAddTravelerProperties,
} from "redmond";
import clsx from "clsx";
import dayjs from "dayjs";

import "./styles.scss";
import { FlightBookPassengerSelectionConnectorProps } from "./container";
import * as constants from "./textConstants";
import { ClientContext } from "../../../../App";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { InfantSeatPickerModal } from "../InfantSeatPickerModal";
import {
  addTrackingProperties,
  useExperiments,
} from "../../../../context/experiments";
import { config } from "../../../../api/config";
import {
  FlightPassengerSelectors,
  ParentState,
  useChildMachineSelector,
  useChildMachineState,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { personToIPerson } from "../../../../utils/personToIPerson";
import { PassengerErrorModal, PassportModal } from "../";
import "./styles.scss";

const getTravelerWorkflowStep = ({
  openPassengerFormModal,
}: {
  openPassengerFormModal: boolean;
}): TravelerSelectStep => {
  switch (true) {
    case openPassengerFormModal:
      return TravelerSelectStep.TravelerInfoForm;
    default:
      return TravelerSelectStep.Main;
  }
};

export interface IFlightBookPassengerSelectionProps
  extends FlightBookPassengerSelectionConnectorProps {}

export const FlightBookPassengerSelection =
  ({}: IFlightBookPassengerSelectionProps) => {
    const [, passengerChildMachineSend] = useChildMachineState<
      TEvent,
      PackagesMachineContext
    >(ParentState.passengerInformation);

    const openPassengerFormModal = useChildMachineSelector(
      ParentState.passengerInformation,
      FlightPassengerSelectors.getOpenPassengerFormModal
    );

    const travelers = useChildMachineSelector(
      ParentState.passengerInformation,
      FlightPassengerSelectors.getUserPassengers
    );

    // all travelers including lap infants
    const allSelectedTravelerIds = useChildMachineSelector(
      ParentState.passengerInformation,
      FlightPassengerSelectors.getAllSelectedPassengerIds
    );

    // all travelers excluding lap infants
    const selectedTravelerIds = useChildMachineSelector(
      ParentState.passengerInformation,
      FlightPassengerSelectors.getSelectedPassengerIds
    );

    const selectedLapInfantIds = useChildMachineSelector(
      ParentState.passengerInformation,
      FlightPassengerSelectors.getSelectedLapInfantIds
    );

    const passengersLoading = useChildMachineSelector(
      ParentState.passengerInformation,
      FlightPassengerSelectors.getIsPassengerInformationLoading
    );

    const hasError = useChildMachineSelector(
      ParentState.passengerInformation,
      FlightPassengerSelectors.getPassengerErrorOpen
    );

    const currentPassenger = useChildMachineSelector(
      ParentState.passengerInformation,
      FlightPassengerSelectors.getCurrentUserPassenger
    );

    const currentInfantId = useChildMachineSelector(
      ParentState.passengerInformation,
      FlightPassengerSelectors.getCurrentInfantId
    );

    // TODO replace once trip details are populated in checkout machine
    // const tripDetails = useCheckoutStateSelector(FlightSelectors.getTripDetails);
    const tripFinalFlightDate =
      // tripDetails.slices[tripDetails.slices?.length - 1].departureTime ||
      new Date().toDateString();

    const { matchesMobile } = useDeviceTypes();

    const expState = useExperiments();

    const handleSelectPassenger = (
      passengerId: PersonId,
      singleTravelerWorkflow?: boolean
    ) => {
      if (hasError) {
        passengerChildMachineSend(Event.CLEAR_PASSENGER_INFORMATION_ERROR);
      }
      if (!allSelectedTravelerIds.includes(passengerId)) {
        // trackEvent({
        //   eventName: CHOOSE_TRAVELER,
        //   properties: chooseTravelerProperties,
        // });
      }
      const selectedPassenger = travelers.find((p) => p.id === passengerId);

      if (selectedPassenger) {
        passengerChildMachineSend({
          type: Event.SET_CURRENT_PASSENGER,
          passenger: selectedPassenger as IPerson,
        });
      }
      passengerChildMachineSend({
        type: Event.SELECT_PASSENGER,
        passengerId,
        singleTravelerWorkflow,
      });
    };

    const handleUpdatePassenger = (passenger: IPerson) => {
      if (!allSelectedTravelerIds.includes(passenger.id)) {
        passengerChildMachineSend({
          type: Event.SET_CURRENT_PASSENGER,
          passenger,
        });
      }

      passengerChildMachineSend({
        type: Event.UPDATE_PASSENGER,
        person: {
          ...passenger,
          // validation will fail if any of these fields are empty so include all or nothing
          passport:
            passenger.passport?.countryOfIssue &&
            passenger.passport?.expiration &&
            passenger.passport?.number
              ? passenger.passport
              : undefined,
        },
      });
    };

    const handleDeletePassenger = (personId: string) => {
      passengerChildMachineSend({ type: Event.DELETE_PASSENGER, personId });
    };

    const handleEditClick = (passenger: IPerson) => {
      passengerChildMachineSend({
        type: Event.SET_CURRENT_PASSENGER,
        passenger,
      });
      passengerChildMachineSend(Event.OPEN_PASSENGER_FORM);
    };

    const handleFormClose = () => {
      passengerChildMachineSend(Event.OPEN_PASSENGER_PICKER);
    };

    const handleClickAddNewTraveler = () => {
      passengerChildMachineSend({
        type: Event.SET_CURRENT_PASSENGER,
        passenger: undefined,
      });
      passengerChildMachineSend(Event.OPEN_PASSENGER_FORM);
    };

    const handleInfantPickerModalClose = () => {
      passengerChildMachineSend(Event.CLEAR_CURRENT_INFANT_ID);
    };

    const handleInfantPickerModalContinue = (seatType: "OwnSeat" | "OnLap") => {
      passengerChildMachineSend({
        type: Event.ON_INFANT_MODAL_CONTINUE,
        seatType,
      });
      handleInfantPickerModalClose();
    };

    const { sessionInfo } = useContext(ClientContext);

    const getIsLapInfant = (traveler: IPerson) => {
      const ageAtBoardingTime = dayjs(tripFinalFlightDate).diff(
        getDateTimeWithFormat(traveler.dateOfBirth, "YYYY-MM-DD"),
        "year"
      );
      return ageAtBoardingTime < 2;
    };

    return (
      <>
        {passengersLoading ? (
          <LoadingIndicator
            className="flight-book-passenger-selection-loading-indicator"
            indicatorSize="small"
            indicator={B2BSpinner}
            message={constants.UPDATE_TEXT}
          />
        ) : (
          <TravelerSelectWorkflow
            showAdditionalInfoSection
            showFrequentFlyerSection
            showGenderField
            showNationalityField
            className={clsx("packages-flight-book-passenger-selection-root")}
            travelers={travelers.map(personToIPerson)}
            progress={getTravelerWorkflowStep({ openPassengerFormModal })}
            setProgress={() => {}} // progress is derived from state machine
            userInfo={sessionInfo?.userInfo}
            titles={{
              travelerInfoTitle: matchesMobile
                ? constants.ADD_TRAVELERS_TEXT
                : constants.TRAVELER_INFO_TITLE_UPDATED,
              travelerInfoSubtitle: constants.TRAVELER_INFO_SUBTITLE,
              frequentFlyerTitle: constants.FREQUENT_FLYER_TITLE,
              additionalInfoTitle: constants.ADDITIONAL_INFO_TITLE,
              adultTitle: constants.ADULT_TITLE,
              childTitle: constants.CHILD_TITLE,
              infantSeatTitle: constants.INFANT_SEAT_TITLE,
              infantLapTitle: constants.INFANT_LAP_TITLE,
              addTravelers: constants.ADD_TRAVELERS_TEXT_UPDATED,
              editTravelerTitle: constants.EDIT_TRAVELER_TEXT,
              travelerInfoFormSubtitle: constants.ADD_TRAVELERS_SUBTITLE,
              travelerInfoSectionTitle: constants.TRAVELER_INFO_TEXT,
              passportTitle: constants.PASSPORT_TITLE,
              passportSubtitle: constants.PASSPORT_SUBTITLE,
              passportSubtitle2: constants.PASSPORT_SUBTITLE_2,
              completeTravelerProfileTitle: constants.COMPLETE_PROFILE_TITLE,
              completeTravelerProfileSubtitle:
                constants.COMPLETE_PROFILE_SUBTITLE,
            }}
            selectedTravelerIds={[
              ...selectedTravelerIds,
              ...selectedLapInfantIds,
            ]}
            handleSelectTraveler={handleSelectPassenger}
            handleUpdatePassenger={(
              traveler: IPerson
              // hideProfileAction: ToggleActions,
              // entryPoint?: string,
              // updatePassport?: boolean,
              // isNewTraveler?: boolean
            ) => {
              const freqFlyerKeys = Object.keys(traveler.frequentFlyer);
              if (
                freqFlyerKeys.length > 0 &&
                traveler.frequentFlyer !==
                  travelers.find((t) => t.id === traveler.id)?.frequentFlyer
              ) {
                trackEvent({
                  eventName: ADD_FREQUENT_FLYER,
                  properties: {
                    frequent_flyer_program:
                      freqFlyerKeys.length > 0
                        ? freqFlyerKeys[freqFlyerKeys.length - 1]
                        : "",
                  },
                });
              }
              if (getIsLapInfant(traveler)) {
                handleUpdatePassenger(traveler);
              } else {
                handleUpdatePassenger(traveler);
              }
            }}
            handleDeletePassenger={handleDeletePassenger}
            isMobile={matchesMobile}
            buttonClassName="b2b"
            errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
            isFlights={true}
            // bottomContent={
            //   showVoidWindowNotice || showPassportAwareness ? (
            //     <>
            //       {showVoidWindowNotice && <VoidWindowNotice />}
            //       {showPassportAwareness && <PassportAwarenessCard />}
            //     </>
            //   ) : undefined
            // }
            showPassportSection
            requireNationality
            onClickAddNewTraveler={() => {
              handleClickAddNewTraveler();
              trackEvent({
                eventName: CLICKED_ADD_TRAVELER,
                properties: addTrackingProperties(expState.trackingProperties, {
                  entry_type: "checkout",
                } as ClickedAddTravelerProperties),
              });
            }}
            onClickEditTraveler={(traveler) => handleEditClick(traveler)}
            tenant={config.TENANT}
            trackEvent={trackEvent}
            setSelectedTravelerIds={() => {}}
            editPassenger={
              currentPassenger ? personToIPerson(currentPassenger) : undefined
            }
            onTravelerFormClose={handleFormClose}
          />
        )}
        <InfantSeatPickerModal
          currentInfantToSelectSeat={currentInfantId}
          handleInfantPickerModalClose={handleInfantPickerModalClose}
          onContinue={handleInfantPickerModalContinue}
        />
        <PassengerErrorModal />
        <PassportModal />
      </>
    );
  };
