import React, { useState } from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ActionButton, Icon, IconName } from "halifax";

import "./styles.scss";
import { FlightShopHeaderConnectorProps } from "./container";
import * as constants from "./constants";
import dayjs from "dayjs";

import { useInView } from "react-intersection-observer";
import { PackagesShopProgressBar } from "../../../book/components";
import { FlightShopAllFiltersModal } from "../FlightShopAllFiltersModal";
import { FlightShopFilters } from "../FlightShopFilters";
import { SortOptionSelection } from "../SortOptionSelection";
import { FILTERS_MODAL_CTA_TEXT } from "../FlightShopAllFiltersModal/textConstants";
import { airCXV3SortOptionsToDisplay } from "../../../../utils/sortAndFilter";
import { IIdFlight, ITripTerminus } from "redmond";
import { FareClassesInfo } from "./FareClassesInfo";
import "./styles.scss";
import { SelectedPackagePricingCard } from "./SelectedPackagePricingCard";
import { FlightSummaryPanel } from "./FlightSummaryPanel";

export interface IFlightShopHeaderProps extends FlightShopHeaderConnectorProps {
  isMobile: boolean;
  isMediumDesktop?: boolean;
}

export const FlightShopHeader = (props: IFlightShopHeaderProps) => {
  const {
    destination,
    origin,
    isMobile,
    isMediumDesktop,
    isOutgoing,
    departureDate,
    returnDate,
    activeFiltersCount,
    sortOption,
    setSortOption,
    airportMap,
  } = props;

  const [allFiltersModalOpen, setAllFiltersModalOpen] = useState(false);

  const fixedShelfOffset = React.useMemo(
    () =>
      (document
        .querySelector(".b2b-portal-banner-root")
        ?.getBoundingClientRect().height || 0) +
      (document.querySelector(".app-header")?.getBoundingClientRect().height ||
        0) +
      (document
        .querySelector(".travel-wallet-offer-banner-root")
        ?.getBoundingClientRect().height || 0),
    []
  );

  const [
    _fixedFareClassShelfPlaceholderRef,
    _fixedFareClassShelfPlaceholderInView,
  ] = useInView({
    threshold: 1,
    rootMargin: `-${fixedShelfOffset}px 0px 0px 0px`,
  });

  const [_fixedFareClassShelfRef, _fixedFareClassShelfInView] = useInView();

  const getCurrentStepFlightListTitle = () => {
    if (!airportMap) return "";
    let originAirport, destinationAirport;

    originAirport = !!origin ? airportMap[origin?.id.code.code] : undefined;
    destinationAirport = !!destination
      ? airportMap[(destination?.id as IIdFlight).code?.code]
      : undefined;

    return constants.FLIGHT_LIST_TITLE(
      isOutgoing ? originAirport : destinationAirport,
      isOutgoing ? destinationAirport : originAirport,
      (isOutgoing ? origin : (destination as ITripTerminus)) || undefined,
      (isOutgoing ? (destination as ITripTerminus) : origin) || undefined
    );
  };

  const getCurrentStepFlightDate = () => {
    return isOutgoing
      ? dayjs(departureDate).format("ddd, MMM D")
      : dayjs(returnDate).format("ddd, MMM D");
  };

  const renderDesktopFlightShopHeader = () => {
    const renderLeftSection = (): JSX.Element => {
      return (
        <>
          {/* TODO: add mini summary */}
          {!isOutgoing && (
            <Box className={clsx("flight-summary-wrapper")}>
              {/* <FlightShopSummaryPanel showDepartureReviewOnly={true} /> */}
            </Box>
          )}
        </>
      );
    };

    return (
      <>
        <Box className={clsx("progress-bar-container")}>
          <PackagesShopProgressBar />
        </Box>
        <Box className={clsx("header-text-and-flight-summary-container")}>
          <Box className="left-section">{renderLeftSection()}</Box>
        </Box>
        <Box className={"filter-entry-points air-cx-v3"}>
          <FlightShopAllFiltersModal
            openAllFiltersModal={allFiltersModalOpen}
            setOpenAllFiltersModal={setAllFiltersModalOpen}
          />
          <ActionButton
            className={clsx("filters-modal-action-button")}
            defaultStyle="h4r-secondary"
            message={FILTERS_MODAL_CTA_TEXT(activeFiltersCount)}
            icon={<Icon name={IconName.Settings} />}
            onClick={() => setAllFiltersModalOpen(true)}
          />
          <FlightShopFilters />
        </Box>
        <Box className="title-and-selected-package">
          <Box className="title-date">
            <Typography variant="h3" className="title">
              {getCurrentStepFlightListTitle()}
            </Typography>
            <Typography className="date">
              {getCurrentStepFlightDate()}
            </Typography>
          </Box>
          <FlightSummaryPanel />
          <SelectedPackagePricingCard />
        </Box>

        <Box className={clsx("sorting-fares", "filter-experiment")}>
          <SortOptionSelection
            sortOption={sortOption}
            setSortOption={setSortOption}
            sortOptions={airCXV3SortOptionsToDisplay}
          />
          <FareClassesInfo />
        </Box>
      </>
    );
  };

  return (
    <Box
      className={clsx("flight-shop-header-root", {
        mobile: isMobile,
        "medium-desktop": isMediumDesktop,
      })}
    >
      <Box className="flight-shop-header-container">
        {!isMobile && renderDesktopFlightShopHeader()}
      </Box>
    </Box>
  );
};
