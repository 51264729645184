import { getObjectKeysAsObject, ParentState } from "@capone/checkout";
import { PackagesMachineContext } from "../types";

export const LodgingPassengerInformationGuards = {
  hasSelectedPassenger: (ctx: PackagesMachineContext) => {
    return !!ctx[ParentState.lodgingPassengerInformation].selectedPassengerId;
  },
};

export const LodgingPassengerInformationGuardTypes = getObjectKeysAsObject(
  LodgingPassengerInformationGuards
);
