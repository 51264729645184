import React from "react";
import {
  TravelerSelectWorkflow,
  useDeviceTypes,
  TravelerSelectStep,
  ActionButton,
  isAdult,
} from "halifax";
import { PersonId, SELECT_TRAVELERS } from "redmond";
import clsx from "clsx";

import "./styles.scss";
import { HotelBookPassengerSelectionConnectorProps } from "./container";
import * as constants from "./textConstants";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import { config } from "../../../../api/config";
import {
  FlightPassengerSelectors,
  ParentState,
  useCheckoutState,
  useCheckoutStateSelector,
  useChildMachineSelector,
} from "@capone/checkout";
import { LodgingSelectors } from "../../state/selectors";
import { personToIPerson } from "../../../../utils/personToIPerson";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { Typography } from "@material-ui/core";

export interface IHotelBookPassengerSelectionProps
  extends HotelBookPassengerSelectionConnectorProps {}

export const HotelBookPassengerSelection =
  ({}: IHotelBookPassengerSelectionProps) => {
    const [_state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

    const travelers = useChildMachineSelector(
      ParentState.passengerInformation,
      FlightPassengerSelectors.getUserPassengers
    );

    const selectedTravelerId = useCheckoutStateSelector(
      LodgingSelectors.getSelectedPassengerIdParent
    );

    const { matchesMobile } = useDeviceTypes();

    const handleSelectPassenger = (passengerId: PersonId) => {
      send({
        type: Event.SELECT_PASSENGER,
        passengerId,
        singleTravelerWorkflow: true,
      });
    };

    const handleContinue = () => {
      send(Event.NEXT);
    };

    React.useEffect(() => {
      if (travelers.length === 1) {
        handleSelectPassenger(travelers[0].id);
      }
    }, [travelers]);

    return (
      <>
        <TravelerSelectWorkflow
          readOnly
          hideTravelerActions
          displayModalSubtitle
          showGenderField
          showNationalityField
          singleTravelerWorkflow
          buttonClassName="b2b"
          className={clsx("packages-hotel-book-passenger-selection-root")}
          errorMessage={constants.ADD_TRAVELER_ERROR_MESSAGE}
          isMobile={matchesMobile}
          progress={TravelerSelectStep.Main}
          setProgress={() => {}}
          selectedTravelerIds={selectedTravelerId ? [selectedTravelerId] : []}
          setSelectedTravelerIds={(travelerIds: string[]) => {
            trackEvent({
              eventName: SELECT_TRAVELERS,
              properties: {},
            });
            handleSelectPassenger(travelerIds[0]);
          }}
          titles={{
            travelerInfoTitle: matchesMobile
              ? constants.TRAVELER_INFO_TEXT
              : constants.TRAVELER_INFO_TITLE_SUBHEADING,
            travelerInfoSubtitle: matchesMobile
              ? constants.TRAVELER_INFO_TITLE_SUBHEADING
              : constants.TRAVELER_INFO_SUBTITLE,
            frequentFlyerTitle: constants.FREQUENT_FLYER_TITLE,
            additionalInfoTitle: constants.ADDITIONAL_INFO_TITLE,
            adultTitle: constants.ADULT_TITLE,
            childTitle: constants.CHILD_TITLE,
            infantSeatTitle: constants.INFANT_SEAT_TITLE,
            infantLapTitle: constants.INFANT_LAP_TITLE,
            addTravelers: matchesMobile
              ? constants.TRAVELER_INFO_TEXT
              : constants.TRAVELER_INFO_TITLE_SUBHEADING,
            completeTravelerProfileTitle: constants.COMPLETE_PROFILE_TITLE,
            completeTravelerProfileSubtitle:
              constants.COMPLETE_PROFILE_SUBTITLE,
          }}
          travelers={travelers
            .filter((person) => isAdult(person.dateOfBirth))
            .map(personToIPerson)}
          tenant={config.TENANT}
          trackEvent={trackEvent}
          // bottomContent={showVoidWindowNotice ? <VoidWindowNotice /> : undefined}
        />
        <Typography className="packages-hotel-pax-select-notice">
          {constants.HOTEL_TRAVELER_NOTICE}
        </Typography>
        <ActionButton
          onClick={handleContinue}
          message={constants.CONTINUE_TEXT}
          className="packages-continue-button"
          disabled={!selectedTravelerId}
        />
      </>
    );
  };
