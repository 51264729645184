import { Box, Button, Divider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { PackageItineraryReviewConnectorProps } from "./container";
import {
  ActionButton,
  Icon,
  IconName,
  PackageHotelPriceAndRewardsDisplay,
  useDeviceTypes,
} from "halifax";
import "./styles.scss";
import { PackagesShopProgressBar } from "../book/components";
import { PATH_BOOK } from "../../utils/paths";
import { RouteComponentProps } from "react-router-dom";
import { ReviewHotelItinerary } from "./components/ReviewHotelItinerary";
import { ReviewFlightItinerary } from "./components/ReviewFlightItinerary";
import clsx from "clsx";
import * as constants from "./constants";
import { ChangeItineraryModal } from "./components/ChangeItineraryModal";

interface IPackageItineraryReviewProps
  extends PackageItineraryReviewConnectorProps,
    RouteComponentProps {}

export const PackageItineraryReview = ({
  finalizePackage,
  selectedPackageByLodging,
  rewardsKey,
  nightCount,
  travelersCount,
  selectedPackageByFlight,
  history,
}: IPackageItineraryReviewProps) => {
  const { matchesMobile } = useDeviceTypes();
  const [openChangeRoomModal, setOpenChangeRoomModal] = useState(false);
  const [openChangeHotelModal, setOpenChangeHotelModal] = useState(false);
  const [openChangeOutboundFlightModal, setOpenChangeOutboundFlightModal] =
    useState(false);

  if (!selectedPackageByLodging) return null;

  const { packageDetails } = selectedPackageByLodging;

  useEffect(() => {
    finalizePackage();
  }, []);

  return (
    <Box
      className={clsx("package-itinerary-review-root", {
        mobile: matchesMobile,
      })}
    >
      {!matchesMobile && <PackagesShopProgressBar />}
      <Box className="hotel-itinerary-header">
        <Icon name={IconName.HotelFunnelIcon} />
        <Typography className="header-text">
          {constants.REVIEW_HOTEL}
        </Typography>
      </Box>
      <Box className={clsx("package-itinerary-review-wrapper")}>
        <Box className="left-section">
          <ReviewHotelItinerary
            setOpenChangeHotelModal={setOpenChangeHotelModal}
            setOpenChangeRoomModal={setOpenChangeRoomModal}
          />
          <Divider />
          <Box className="flight-itinerary-header">
            <Icon name={IconName.FlightFunnelIcon} />
            <Typography className="header-text">
              {constants.REVIEW_FLIGHT}
            </Typography>
          </Box>
          <ReviewFlightItinerary
            setOpenChangeOutboundFlightModal={setOpenChangeOutboundFlightModal}
          />
        </Box>
        {matchesMobile ? (
          <Box className="continue-button-section">
            <ActionButton
              className="continue-button b2b"
              onClick={() => history.push(PATH_BOOK)}
              message={constants.CONTINUE_TO_CHECKOUT}
            />
          </Box>
        ) : (
          <Box className="right-section">
            <Box className="review-itinerary-card-content-container pricing-details">
              <PackageHotelPriceAndRewardsDisplay
                pricing={
                  selectedPackageByFlight?.pricing ?? packageDetails?.pricing
                }
                nightCount={nightCount ?? null}
                travelersCount={travelersCount}
                rewardsKey={rewardsKey}
              />
              <Button
                className="continue-button"
                onClick={() => history.push(PATH_BOOK)}
              >
                {constants.CONTINUE_TO_CHECKOUT}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <ChangeItineraryModal
        type={
          openChangeHotelModal
            ? "hotel"
            : openChangeRoomModal
            ? "room"
            : "outbound-flight"
        }
        openModal={
          openChangeHotelModal ||
          openChangeRoomModal ||
          openChangeOutboundFlightModal
        }
        setOpenModal={
          openChangeHotelModal
            ? setOpenChangeHotelModal
            : openChangeRoomModal
            ? setOpenChangeRoomModal
            : setOpenChangeOutboundFlightModal
        }
        isMobile={matchesMobile}
      />
    </Box>
  );
};
