import React, { useState, useRef } from "react";
import { IDesktopShopProps } from "./container";
import { Box, Typography } from "@material-ui/core";
import {
  HotelCarousel,
  HotelAddress,
  HotelAmenities,
  HotelReviewBadge,
  HotelShopChooseRoomSkeleton,
  HotelTripAdvisorReviews,
  HotelDescription,
} from "halifax";
import clsx from "clsx";
import { DesktopHotelPhotoModal } from "../DesktopHotelPhotoModal";
import * as textConstants from "./textConstants";
import { HotelShopSmallMap } from "../HotelShopSmallMap/component";
import { DesktopHotelShopChooseRoom } from "../DesktopHotelShopChooseRoom";
import "./styles.scss";
import { DesktopRoomPicker } from "../DesktopRoomPicker";
import { HotelShopExpandedMap } from "../HotelShopExpandedMap";
import { DesktopRewardsHeader } from "../DesktopRewardsHeader";
import { PackagesShopProgressBar } from "../../../book/components";

export const DesktopShop = (props: IDesktopShopProps) => {
  const {
    hotelShopCallInProgress,
    lodging,
    fromDate,
    untilDate,
    roomInfoProductsType,
    handleReadyToRedirect,
    setRoomInfoProduct,
    setRoomProductIndex,
  } = props;
  const [showExpandedHotelMap, setShowExpandedHotelMap] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  const renderSkeleton = () => (
    <Box className="desktop-hotel-shop-skeleton">
      <HotelCarousel isSkeleton />
      <Box className="content-container">
        <Box className="section-wrapper-container">
          <Box className={clsx("section-wrapper")}>
            <HotelAddress isSkeleton />
            <HotelAmenities isSkeleton />
            <HotelReviewBadge isSkeleton />
          </Box>
          <Box className={clsx("section-wrapper")}>
            <HotelReviewBadge isSkeleton />
            <HotelReviewBadge isSkeleton />
            <HotelAmenities isSkeleton />
          </Box>
          <Box className={clsx("section-wrapper")}>
            <HotelAmenities isSkeleton />
            <HotelAddress isSkeleton />
            <HotelAmenities isSkeleton />
          </Box>
        </Box>
        <HotelShopChooseRoomSkeleton />
      </Box>
    </Box>
  );

  const renderShop = () => {
    const { lodging: lodgingData } = lodging || {};

    if (!lodging) {
      return null;
    }
    return (
      <>
        <Box className={clsx("desktop-hotel-shop-container")}>
          <DesktopHotelPhotoModal />
          <Box className={clsx("hotel-shop-right-column")}>
            <DesktopHotelShopChooseRoom />
          </Box>
          {lodgingData?.amenities && lodgingData?.amenities.length > 0 && (
            <Box className={clsx("section-wrapper")}>
              <Typography variant="h2" className="hotel-amenities-title">
                Hotel Amenities
              </Typography>
              <HotelAmenities
                amenities={lodgingData?.amenities}
                displayCount={8}
                showAmenitiesText={`More Amenities (${
                  (lodgingData?.amenities.length ?? 8) - 8
                })`}
                hideAmenityIconsInTooltip
              />
            </Box>
          )}
          <Box className={clsx("section-wrapper")}>
            <Typography variant="h2">About the Property</Typography>
            <HotelDescription
              description={lodging?.lodging?.description}
              onMapClick={() => {
                setShowExpandedHotelMap(true);
              }}
              map={
                <HotelShopSmallMap
                  lodging={lodging}
                  setShowExpandedHotelMap={setShowExpandedHotelMap}
                />
              }
            />
            <HotelShopExpandedMap
              lodging={lodging}
              showExpandedHotelMap={showExpandedHotelMap}
              setShowExpandedHotelMap={setShowExpandedHotelMap}
            />
          </Box>
          {lodgingData?.tripAdvisorReviews &&
            lodgingData?.tripAdvisorReviews?.comments?.length > 0 && (
              <Box className={clsx("section-wrapper")}>
                <Typography variant="h2">Customer Reviews</Typography>
                <HotelTripAdvisorReviews
                  lodgingData={lodgingData}
                  trim={1000}
                />
              </Box>
            )}
          {lodgingData?.checkInInstructions && fromDate && untilDate && (
            <Box className={clsx("section-wrapper")}>
              <Typography
                variant="h4"
                className="hotel-shop-check-in-instructions-heading"
              >
                {textConstants.CHECK_IN_INSTRUCTIONS_TITLE}
              </Typography>
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: textConstants.formattedCheckIn(
                    fromDate,
                    lodgingData.checkInInstructions?.checkInTime
                  ),
                }}
              />
              <Typography
                variant="body2"
                dangerouslySetInnerHTML={{
                  __html: textConstants.formattedCheckOut(
                    untilDate,
                    lodgingData.checkInInstructions?.checkOutTime
                  ),
                }}
              />
            </Box>
          )}
        </Box>
        <div ref={ref}>
          <Box
            id="hotel-shop-room-type-section"
            className={clsx(
              "hotel-shop-room-type-section-wrapper-redesign",
              "white-bg"
            )}
          >
            <Typography className="choose-bed-type-header" variant="h2">
              Choose Room
            </Typography>
            <DesktopRoomPicker
              handleReadyToRedirect={handleReadyToRedirect}
              roomInfoProductsType={roomInfoProductsType}
              setRoomInfoProduct={setRoomInfoProduct}
              setRoomProductIndex={setRoomProductIndex}
            />
          </Box>
        </div>
      </>
    );
  };

  return (
    <Box className="desktop-package-hotel-shop-wrapper">
      <DesktopRewardsHeader />
      <PackagesShopProgressBar />
      {hotelShopCallInProgress ? renderSkeleton() : renderShop()}
    </Box>
  );
};
