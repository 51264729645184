import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import {
  getAdultsCount,
  getChildren,
  getDepartureDate,
  getInfants,
  getOrigin,
  getReturnDate,
  getStopsOption,
} from "../../../search/reducer";
import {
  fetchPackageHotelShop,
  setPackageHotelShopProgress,
} from "../../actions/actions";
import { IStoreState } from "../../../../reducers/types";
import { MobileShopHeader } from "./component";
import {
  getPackageSelectedLodging,
  hotelShopProgressSelector,
} from "../../reducer/selectors";
import { actions } from "../../../search/actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    origin: getOrigin(state),
    fromDate: getDepartureDate(state),
    untilDate: getReturnDate(state),
    adultsCount: getAdultsCount(state),
    children: getChildren(state),
    infants: getInfants(state),
    stopsOption: getStopsOption(state),
    selectedLodging: getPackageSelectedLodging(state),
    hotelShopStep: hotelShopProgressSelector(state),
  };
};

const mapDispatchToProps = {
  fetchPackageHotelShop,
  setDepartureDate: actions.setDepartureDate,
  setReturnDate: actions.setReturnDate,
  setPackageHotelShopProgress,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileShopHeaderConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileShopHeader = withRouter(
  connector(MobileShopHeader)
);
