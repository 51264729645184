import { StateValue, send as xstateSend } from "xstate";
import * as H from "history";
import queryStringParser from "query-string";
import { CheckoutQueryParams, ParentState } from "@capone/checkout";
import { PackagesMachineContext } from "./types";
import { Event } from "./events";
import { CurrencyFormatters } from "halifax";
import { FiatPrice, RewardsAccount, RewardsPrice } from "redmond";

export const stringifyParentStateValue = (state: StateValue) => {
  if (typeof state === "string") return state;
  else if (typeof state === "object") {
    const key = Object.keys(state)[0];
    return key;
  } else {
    return "";
  }
};

const untrackedStates = [
  ParentState.loading,
  ParentState.passport,
  ParentState.cartQuote,
  ParentState.cartUpdate,
  ParentState.cartUpdateBeforeFulfill,
  ParentState.priceFreeze,
  ParentState.priceFreezeDiscount,
  ParentState.cartFulfill,
  ParentState.disruption,
  ParentState.changeForAnyReasonDiscount,
  ParentState.wallet,
];

// Map of states that should not transition when query params change
// Map: { state that should not transition => previous tracked state }
// Eg: if user is in "cartQuote" and hits back on their browser, the user should not be allowed to go back 1 step and
// instead stay in the current step till it resolves
const preventTransitionStates: Record<string, ParentState> = {
  [ParentState.cartQuote]: ParentState.passengerInformation,
  [ParentState.cartUpdate]: ParentState.passengerInformation,
  [ParentState.cartUpdateBeforeFulfill]: ParentState.review,
  [ParentState.cartFulfill]: ParentState.review,
};

export const populateCheckoutQueryParams = (
  state: StateValue,
  history: H.History
) => {
  const queryString = history?.location?.search || "";
  const parsedQuery = queryStringParser.parse(queryString);
  const stateQueryParam = parsedQuery?.[CheckoutQueryParams.checkoutState];

  const stringifiedNewState = stringifyParentStateValue(state);

  const shouldUpdateQuery = !untrackedStates.includes(
    stringifiedNewState as ParentState
  );

  //  If xstate state value is not in query params or if it conflicts with what the
  //  current query params contains, then we push new query params to the URL

  const historyData = {
    pathname: history.location.pathname,
    search: queryStringParser.stringify({
      ...parsedQuery,
      [CheckoutQueryParams.checkoutState]: stringifiedNewState,
    }),
    state: history.location.state,
  };

  if (!stateQueryParam && shouldUpdateQuery) {
    history.replace(historyData);
  } else if (
    stateQueryParam &&
    stringifiedNewState !== stateQueryParam &&
    shouldUpdateQuery
  ) {
    history.push(historyData);
  }
};

export const mapFlightStateToTransitionEvent: Record<string, Event> = {
  [ParentState.contactInformation]: Event.GO_TO_CONTACT_INFORMATION,
  [ParentState.passengerInformation]: Event.GO_TO_PASSENGER_SELECT,
  [ParentState.review]: Event.GO_TO_REVIEW,
  [ParentState.bookingConfirmation]: Event.GO_TO_REVIEW,
  [ParentState.seatSelection]: Event.GO_TO_SEAT_SELECTION,
  [ParentState.cardPayment]: Event.GO_TO_CARD_PAYMENT,
};

export const handleFulfillSuccess = (history: H.History) => {
  history.push("/");
};

export const transitionStateOnPathnameChange = (
  state: StateValue,
  history: H.History,
  send: typeof xstateSend
) => {
  const queryString = history.location.search;
  const parsedQuery = queryStringParser.parse(queryString);
  const stateQueryParam = parsedQuery?.[CheckoutQueryParams.checkoutState];

  const stringifiedNewState = stringifyParentStateValue(state);

  const preventTransition = !!preventTransitionStates[stringifiedNewState];

  const allowTransition =
    !preventTransition &&
    stateQueryParam &&
    !untrackedStates.includes(stateQueryParam as ParentState);

  const hasSucceededFulfillment =
    stringifiedNewState === ParentState.bookingConfirmation;

  if (
    stateQueryParam &&
    stringifiedNewState !== stateQueryParam &&
    allowTransition
  ) {
    if (hasSucceededFulfillment) {
      handleFulfillSuccess(history);
    } else {
      send({
        type: mapFlightStateToTransitionEvent[stateQueryParam as ParentState],
      });
    }
  } else if (
    preventTransition &&
    preventTransitionStates[stringifiedNewState] !== stateQueryParam
  ) {
    const historyData = {
      pathname: history.location.pathname,
      search: queryStringParser.stringify({
        ...parsedQuery,
        [CheckoutQueryParams.checkoutState]:
          preventTransitionStates[stringifiedNewState],
      }),
      state: history.location.state,
    };
    // Pushing the same stack that was removed when the user goes 1 step back in their browser
    history.push(historyData);
  }
};

export const validateContext = (_: PackagesMachineContext) => true;
// Boolean(
//   (ctx.flightShop.shopPricingInfo.fare?.length || -1) > 0 &&
//     ctx.flightSearch.departureDate &&
//     ctx.flightSearch.destination &&
//     ctx.flightSearch.origin &&
//     ctx.flightShop.selectedTrip.outgoingFareId &&
//     ctx.flightShop.selectedTrip.outgoingSliceId &&
//     ctx.flightShop.selectedTrip.tripId &&
//     Object.keys(ctx.flightShop.airports || {}).length > 0
// );

export function integerWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function numberWithCommas(x: number) {
  return (
    integerWithCommas(Math.floor(x)) +
    (x % 1 ? "." + Math.round((x % 1) * 100) : "")
  );
}

export const formatCurrency = (price: FiatPrice, discount?: boolean) =>
  CurrencyFormatters.get(price.currencyCode).format(
    discount ? Math.abs(price.value) * -1 : price.value
  );

export const formatRewards = (price: RewardsPrice, discount?: boolean) => {
  const priceValueToUse = discount ? Math.abs(price.value) : price.value;

  const currencySymbol = price.currencyDescription?.includes("cash") ? "$" : "";
  return `${
    discount ? "-" : ""
  }${currencySymbol}${priceValueToUse.toLocaleString("en-US")} ${
    price?.currencyDescription
  }`;
};

export const getRewardsAmountFromFiat = (
  usdAmount: number,
  rewardsAccount: RewardsAccount
): RewardsPrice => {
  const amountToUse =
    usdAmount *
    (rewardsAccount.rewardsBalance.currencyDescription?.includes("cash")
      ? 1
      : 100);

  return {
    ...rewardsAccount.rewardsBalance,
    value: amountToUse,
  };
};
