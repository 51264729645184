import { put, putResolve, select } from "redux-saga/effects";
import { actions } from "../actions";
import Logger from "../../../utils/logger";
import {
  CallState,
  PackagedRoom,
  Platform,
  ShopFilter,
  ShopFlightsRequest,
  ShopFlightsResponse,
} from "redmond";
import { fetchPackagesFlightShop } from "../../../api/v0/flight-shop/fetchPackagesFlightShop";
import { IStoreState } from "../../../reducers/types";
import { selectedPackageByRateId } from "../../hotel-shop/reducer";

export default function* fetchPackagesFlightShopSaga() {
  try {
    const state: IStoreState = yield select();
    let selectedPackage: PackagedRoom | undefined =
      yield selectedPackageByRateId(state);

    const shopPackageId = selectedPackage?.packageDetails.opaquePackageToken;

    if (!shopPackageId) {
      throw new Error("Shop Request Id must be present.");
    }

    const requestBody: ShopFlightsRequest = {
      opaquePackageToken: shopPackageId,
      // TODO: Change this to use actual params
      flightShopFilter: ShopFilter.NoFilter,
      platform: Platform.Desktop,
    };

    const response: ShopFlightsResponse = yield fetchPackagesFlightShop(
      requestBody
    );

    yield putResolve(
      actions.setPackagesFlightShopResults({
        flightShopCallState: CallState.Success,
        flightShopResults: response,
      })
    );
  } catch (e) {
    Logger.debug(e);
    yield put(actions.setPackagesFlightShopCallStateFailed(e));
  }
}
