import React from "react";
import { RewardsPrice } from "redmond";
import { formatRewards } from "../../state/utils";
import dayjs from "dayjs";

export const PACKAGE_CONFIRMATION_HEADING =
  "Confirmed, your package is booked!";
export const PACKAGE_CONFIRMATION_EARN = (
  earn?: RewardsPrice,
  email?: string
) => (
  <>
    {earn ? (
      <>
        You’ve earned <strong>{formatRewards(earn)}</strong> by booking this
        package!
      </>
    ) : (
      ""
    )}
    {email ? <>An email confirmation will be sent to {email}</> : ""}
  </>
);
export const PACKAGE_CONFIRMATION_CODE_TEXT = (confirmationCode: string) =>
  `Your confirmation is #${confirmationCode}`;

export const REVIEW_CARD_HEADER_TEXT = (
  isDeparture: boolean,
  location: string,
  date: string,
  airlineCode?: string
) => ({
  bold: isDeparture ? "Outbound" : "Return",
  standard: `to ${location}${airlineCode ? ` (${airlineCode})` : ""} on ${dayjs(
    date
  ).format("ddd, MMM D")}`,
});

export const STOPS_STRING = (stops: number) =>
  stops === 0 ? "Nonstop" : stops > 1 ? `${stops} stops` : "1 stop";

export const TREES_MODAL_TITLE = "How it works";
export const TREES_MODAL_SUBTITLE =
  "<b>Tree Planting Efforts:</b> Capital One, together with its partners, will donate to GlobalGiving’s fund for EarthLungs’ Reforestation Project enough to plant two (2) trees, $0.25, for every completed flight, hotel booking, car rental, or vacation rental booking made through Capital One Travel.  In the event that a booking is made, but later canceled and refunded to the customer, for any reason, such a payment will not be made. No portion of a customer’s Capital One Travel purchase is tax-deductible. <br>GlobalGiving is a 501(c)(3) nonprofit organization whose mission is to transform aid and philanthropy to accelerate community-led change. To learn more about GlobalGiving, visit <a href='www.globalgiving.org' target='_blank'>www.globalgiving.org</a>; to learn more about GlobalGiving’s nonprofit partner, EarthLungs Reforestation Project, visit <a href='http://www.earthlungsreforestation.org.' target='_blank'>www.earthlungsreforestation.org.";
export const TREES_MODAL_HEADER =
  "When you book, <span class='font-regular'>you plant trees.</span>";
export const TREES_MODAL_IMG_LOCATION = "Banff, Alberta";
export const TREES_MODAL_CTA_TEXT = "will be planted for this booking! ";
export const TREES_BOLDED_MODAL_CTA_TEXT = "2 trees ";

export const WHATS_NEXT_HEADING = "What’s next?";
export const WHATS_NEXT_CARS_TITLE = "Rent a Car";
export const WHATS_NEXT_CARS_DESCRIPTION =
  "Search now and get the best deals on wheels.";
