import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { IStoreState } from "../../reducers/types";

import { PackageItineraryReview } from "./component";
import { getSelectedReturnFlightPackage } from "../flight-shop/reducer";
import { finalizePackage } from "./actions/actions";
import { getSelectedPackageByLodgingId } from "../hotel-shop/reducer";
import { getNightCount } from "../search/reducer";
import { getTravelersCount } from "../availability/reducer";
import { getSelectedAccountReferenceIdIfRedemptionEnabled } from "../rewards/reducer";

const mapStateToProps = (state: IStoreState) => {
  return {
    selectedPackageByLodging: getSelectedPackageByLodgingId(state),
    rewardsKey:
      getSelectedAccountReferenceIdIfRedemptionEnabled(state) || undefined,
    nightCount: getNightCount(state),
    travelersCount: getTravelersCount(state),
    selectedPackageByFlight: getSelectedReturnFlightPackage(state),
  };
};

export const mapDispatchToProps = { finalizePackage };

const connector = connect(mapStateToProps, mapDispatchToProps);
export type PackageItineraryReviewConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackageItineraryReview = withRouter(
  connector(PackageItineraryReview)
);
