import dayjs from "dayjs";
import { History } from "history";
import queryStringParser from "query-string";
import {
  FareclassShelfBrandName,
  FareclassShelfBrandNameMapping,
  SliceStopCountFilter,
} from "redmond";

export interface IPackagesParsedQuery {
  readonly origin?: string;
  readonly destination?: string;
  readonly fromDate: Date | null;
  readonly untilDate: Date | null;
  readonly stopsOption: SliceStopCountFilter | null;
  readonly fareClass: FareclassShelfBrandName | null;
}
export const parseQueryString = (
  history: History
): IPackagesParsedQuery | null => {
  const queryString = history?.location?.search || "";
  return queryString ? parseQueryFromString(queryString) : null;
};

export const parseQueryFromString = (
  queryString: string
): IPackagesParsedQuery => {
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);

  return {
    destination:
      (parsedQueryStringPrimitive.destination as string) ?? undefined,
    origin: (parsedQueryStringPrimitive.origin as string) ?? undefined,
    fromDate: parsedQueryStringPrimitive.fromDate
      ? dayjs(parsedQueryStringPrimitive.fromDate as string).toDate()
      : null,
    untilDate: parsedQueryStringPrimitive.untilDate
      ? dayjs(parsedQueryStringPrimitive.untilDate as string).toDate()
      : null,
    stopsOption: parsedQueryStringPrimitive.stopsOption
      ? (parsedQueryStringPrimitive.stopsOption as SliceStopCountFilter)
      : null,
    fareClass:
      parsedQueryStringPrimitive.fareClass &&
      Object.keys(FareclassShelfBrandNameMapping).includes(
        parsedQueryStringPrimitive.fareClass as string
      )
        ? (parsedQueryStringPrimitive.fareClass as FareclassShelfBrandName)
        : null,
  };
};
