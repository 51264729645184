import React, { useState } from "react";
import "./styles.scss";
import { RouteComponentProps } from "react-router-dom";
import { ActionButton, ContactInfoForm, IContactInfo } from "halifax";
import { ITravelerStepErrors } from "redmond";
import { PackagesContactInfoFormConnectorProps } from "./container";
import {
  ContactSelectors,
  FlightPassengerSelectors,
  GenericChildState,
  getChildState,
  getParentState,
  ParentState,
  useCheckoutState,
  useCheckoutStateSelector,
  useChildMachineSelector,
  useChildMachineState,
} from "@capone/checkout";
import { Event, TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import {
  CONTACT_INFO_NOTICE,
  CONTACT_INFO_SUBTITLE,
  CONTACT_INFO_TITLE,
  CONTINUE_TEXT,
  SAVING_TEXT,
} from "./textConstants";
import { Typography } from "@material-ui/core";

export interface PackagesContactInfoFormProps
  extends PackagesContactInfoFormConnectorProps,
    RouteComponentProps {}

export const PackagesContactInfoForm = ({}: PackagesContactInfoFormProps) => {
  const [showErrors, setShowErrors] = useState<ITravelerStepErrors>({
    phone: false,
    email: false,
    travelerSelect: false,
  });

  const [state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const [, passengerChildMachineSend] = useChildMachineState<
    TEvent,
    PackagesMachineContext
  >(ParentState.passengerInformation);

  const parentState = getParentState(state.value) as ParentState;
  const childState = getChildState(state.value);

  const contactInfo = useCheckoutStateSelector(
    ContactSelectors.getContactInformation
  );

  const contactInfoLoading = useCheckoutStateSelector(
    ContactSelectors.getContactInformationLoading
  );

  const passengersValidated = useChildMachineSelector(
    ParentState.passengerInformation,
    FlightPassengerSelectors.getPassengerStateValidated
  );

  const contactInfoValidated = useCheckoutStateSelector(
    ContactSelectors.getContactInformationValidated
  );

  const handleContinue = () => {
    passengerChildMachineSend(Event.NEXT);
  };

  const onContactInfoChange = (contactInfo: IContactInfo) => {
    send({ type: Event.CHANGE, contactInfo });
  };

  return (
    <>
      <ContactInfoForm
        disabled={
          ![
            ParentState.contactInformation,
            ParentState.passengerInformation,
          ].includes(parentState)
        }
        title={CONTACT_INFO_TITLE}
        subtitle={CONTACT_INFO_SUBTITLE}
        contactInfo={contactInfo}
        onContactInfoChange={onContactInfoChange}
        showErrors={showErrors}
        setShowErrors={setShowErrors}
        loading={contactInfoLoading}
        className="packages-contact-info-form"
      />
      <Typography className="packages-contact-info-notice">
        {CONTACT_INFO_NOTICE}
      </Typography>
      <ActionButton
        onClick={handleContinue}
        message={
          childState === GenericChildState.saving ? SAVING_TEXT : CONTINUE_TEXT
        }
        className="packages-continue-button"
        disabled={
          !(contactInfoValidated && passengersValidated) ||
          childState === GenericChildState.saving
        }
      />
    </>
  );
};
