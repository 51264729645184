import { Box, Typography } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { PackagesSearchControl } from "./components/PackagesSearchControl";
import "./styles.scss";
import { PackagesSearchConnectorProps } from "./container";
import { RouteComponentProps } from "react-router-dom";
import { CallState } from "redmond";
import { Skeleton } from "@material-ui/lab";
import { SUBTITLE, TITLE, INFO_MODAL_CTA_TEXT } from "./textConstants";
import { useDeviceTypes } from "halifax";
import { MobilePackagesSearchControl, PackagesInfoModal } from "./components";
import { ButtonWrap, Icon, IconName } from "halifax";
import { PORTAL_TITLE, SEARCH_PACKAGE_TITLE } from "../../lang/textConstants";
import { listPaymentMethods } from "../availability/actions/actions";
import { ClientContext } from "../../App";
import {
  IPackagesParsedQuery,
  parseQueryString,
} from "./utils/parseQueryString";
import { fetchFlightLocation } from "../../api/v0/search/fetchFlightLocation";
import { fetchHotelLocation } from "../../api/v0/search/fetchHotelLocation";

interface IPackagesSearchProps
  extends PackagesSearchConnectorProps,
    RouteComponentProps {}

export const PackagesSearch = ({
  largestValueAccount,
  fetchRewardsAccountsCallState,
  fetchRewardsAccounts,
  resetPackagesAvailabilityCallState,
  setDepartureDate,
  setReturnDate,
  setOrigin,
  setDestination,
  setFareclassOptionFilter,
  setStopsOption,
  history,
}: IPackagesSearchProps) => {
  const { matchesMobile } = useDeviceTypes();
  const { sessionInfo } = useContext(ClientContext);

  const [packagesInfoModalOpen, setPackagesInfoModalOpen] =
    React.useState(false);

  const getSubtitleToDisplay = () => {
    if (
      (fetchRewardsAccountsCallState === CallState.Success ||
        fetchRewardsAccountsCallState === CallState.Failed) &&
      largestValueAccount
    ) {
      return SUBTITLE(largestValueAccount);
    } else {
      return <Skeleton className="subtitle-loading" />;
    }
  };

  const parsedQueryString = parseQueryString(history);

  const populateFieldsFromQueryString = async ({
    fromDate,
    untilDate,
    origin,
    destination,
    stopsOption,
    fareClass,
  }: IPackagesParsedQuery) => {
    if (fromDate) {
      setDepartureDate(fromDate);
    }

    if (untilDate) {
      setReturnDate(untilDate);
    }

    if (origin) {
      const originLocation = await fetchFlightLocation(origin);
      setOrigin(originLocation);
    }

    if (destination) {
      const destinationLocation = await fetchHotelLocation(destination);
      setDestination(destinationLocation);
    }

    if (stopsOption) {
      setStopsOption(stopsOption);
    }

    if (fareClass) {
      setFareclassOptionFilter({
        basic: false,
        standard: false,
        enhanced: false,
        premium: false,
        luxury: false,
        [fareClass]: true,
      });
    }
  };

  useEffect(() => {
    document.title = SEARCH_PACKAGE_TITLE;
    fetchRewardsAccounts(false, sessionInfo);
    listPaymentMethods();

    setTimeout(() => window.scrollTo(0, 0), 0);

    resetPackagesAvailabilityCallState();

    if (parsedQueryString) {
      (async () => {
        await populateFieldsFromQueryString(parsedQueryString);
      })();
    }

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  if (matchesMobile)
    return (
      <Box className="mobile-packages-search-root">
        <MobilePackagesSearchControl />
      </Box>
    );

  return (
    <Box className="packages-search-root">
      <Box className="packages-search-container-desktop">
        <Box className="packages-search-titles-and-fields-wrapper">
          <Typography variant="h1" className="search-title">
            {TITLE}
          </Typography>
          <Typography variant="h2" className="search-subtitle">
            {getSubtitleToDisplay()}
          </Typography>

          <PackagesSearchControl />

          <ButtonWrap
            className="packages-info-cta"
            onClick={() => setPackagesInfoModalOpen(true)}
          >
            <Box className="new-tag">New</Box>
            <Typography className="packages-info-cta-text">
              {INFO_MODAL_CTA_TEXT}
            </Typography>
            <Icon name={IconName.InfoCircle} />
          </ButtonWrap>

          <PackagesInfoModal
            open={packagesInfoModalOpen}
            setOpen={setPackagesInfoModalOpen}
          />
        </Box>
      </Box>
    </Box>
  );
};
