import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { IStoreState } from "../../../../reducers/types";
import {
  getSearchedNightCount,
  getTravelersCount,
} from "../../../availability/reducer";
import {
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
} from "../../../rewards/reducer";
import {
  getAdultsCount,
  getChildren,
  getDepartureDate,
  getInfants,
  getOrigin,
  getReturnDate,
  getStopsOption,
} from "../../../search/reducer";
import { PackageHotelShopCallState } from "../../reducer/state";
import {
  getCheapestLodgingRoomProduct,
  getHotelShopCallState,
  getHotelShopCancellationSummary,
  getHotelShopChosenProduct,
  getPackageRatesById,
  getPackageSelectedLodging,
  hasHotelShopFailed,
  hotelShopProgressSelector,
} from "../../reducer/selectors";
import { MobileShop } from "./component";
// import { actions } from "../../actions";

const mapStateToProps = (state: IStoreState) => {
  return {
    lodging: getPackageSelectedLodging(state),
    cheapestProduct: getCheapestLodgingRoomProduct(state),
    hotelShopCallInProgress:
      getHotelShopCallState(state) === PackageHotelShopCallState.InProcess,
    chosenProduct: getHotelShopChosenProduct(state),
    hasFailed: hasHotelShopFailed(state),
    hotelShopStep: hotelShopProgressSelector(state),
    fromDate: getDepartureDate(state),
    untilDate: getReturnDate(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    adultsCount: getAdultsCount(state),
    children: getChildren(state),
    hotelShopCallState: getHotelShopCallState(state),
    nightCount: getSearchedNightCount(state),
    rewardsKey: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    packagesByRateId: getPackageRatesById(state),
    travelersCount: getTravelersCount(state),
    cancellationSummary: getHotelShopCancellationSummary(state),
    origin: getOrigin(state),
    infants: getInfants(state),
    stopsOption: getStopsOption(state),
  };
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileShopConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedMobileShop = withRouter(connector(MobileShop));
