import React from "react";
import { Box } from "@material-ui/core";
import { useDeviceTypes } from "halifax";
import { Route, useHistory } from "react-router-dom";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";

import { PackagesBookConnectorProps } from "./container";
import {
  // BookingErrorModal,
  // BookingInProgressModal,
  // BookingSuccessModal,
  DesktopPackagesBookWorkflow,
  MobilePackagesBookWorkflow,
  PackageConfirmation,
} from "./components";
import { PATH_BOOK, PATH_BOOK_CONFIRMATION } from "../../utils/paths";
import "./styles.scss";
import { CheckoutStateProvider } from "@capone/checkout";
import { packagesBookStateMachine } from "./state/stateMachine";
import { getInitialPackagesMachineContext } from "./state/initialContext";
import { Actions } from "./state/actions";
import * as services from "./state/services";
import { Guards } from "./state/guards";
import {
  populateCheckoutQueryParams,
  transitionStateOnPathnameChange,
  validateContext,
} from "./state/utils";

export interface IPackagesBookProps
  extends RouteComponentProps,
    PackagesBookConnectorProps {}

export const PackagesBook = (props: IPackagesBookProps) => {
  const { location } = props;

  const { matchesMobile, matchesDesktop } = useDeviceTypes();
  const history = useHistory();

  return (
    <CheckoutStateProvider
      stateMachine={packagesBookStateMachine}
      actions={Actions}
      guards={Guards}
      context={getInitialPackagesMachineContext()}
      services={services}
      getInitialContext={getInitialPackagesMachineContext}
      onStateValueChange={populateCheckoutQueryParams}
      onPathnameChange={transitionStateOnPathnameChange}
      validateContext={validateContext as (ctx: unknown) => boolean}
      history={history}
    >
      <Box
        className={clsx(
          "packages-book-root",
          { confirm: location.pathname === PATH_BOOK_CONFIRMATION },
          { mobile: matchesMobile }
        )}
      >
        <Route path={PATH_BOOK} exact>
          <>
            <Box className="packages-book-container">
              {matchesDesktop && <DesktopPackagesBookWorkflow />}
              {matchesMobile && <MobilePackagesBookWorkflow />}
            </Box>
          </>
        </Route>
        <Route path={PATH_BOOK_CONFIRMATION}>
          <PackageConfirmation />
        </Route>
      </Box>
    </CheckoutStateProvider>
  );
};
