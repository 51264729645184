import dayjs from "dayjs";
import { SliceStopCountFilter } from "redmond";

export const transformToStringifiedAvailabilityQuery = (
  origin: string,
  destination: string,
  fromDate: Date | string,
  untilDate: Date | string,
  adultsCount: number | null,
  children: number[] | null,
  infants: {
    age: number;
    inSeat: boolean;
  }[],
  stopsOptions: SliceStopCountFilter
  //   roomsCount: number
): string => {
  destination = encodeURIComponent(destination);

  const formatFrom = dayjs(fromDate).format("YYYY-MM-DD");
  const formatUntil = dayjs(untilDate).format("YYYY-MM-DD");

  let query = `?origin=${origin}&destination=${destination}&fromDate=${formatFrom}&untilDate=${formatUntil}&adultsCount=${adultsCount}${children?.reduce(
    (ageString, age) => `${ageString}&children=${age}`,
    ""
  )}${infants?.reduce(
    (infantString, infant) =>
      `${infantString}&infants=${encodeURIComponent(JSON.stringify(infant))}`,
    ""
  )}&stopsOption=${stopsOptions}`;
  //   query += `&roomsCount=${roomsCount}`;

  return query;
};
