import { Box, Button, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { ReviewHotelItineraryConnectorProps } from "./container";
import {
  getCancellationPolicyInfo,
  HotelNeedToKnowPanel,
  HotelSummaryPanel,
  Icon,
  IconName,
  useDeviceTypes,
} from "halifax";
import {
  formattedCheckIn,
  formattedCheckOut,
  NEED_TO_KNOW_DETAILS,
  NEED_TO_KNOW_TITLE,
  CHECK_IN_INSTRUCTIONS_TITLE,
} from "./constants";
import clsx from "clsx";
import { RouteComponentProps } from "react-router-dom";
import { CheckInPolicy } from "redmond";
import "./styles.scss";

interface IReviewHotelItineraryProps
  extends ReviewHotelItineraryConnectorProps,
    RouteComponentProps {
  setOpenChangeHotelModal: (arg: boolean) => void;
  setOpenChangeRoomModal: (arg: boolean) => void;
}

export const ReviewHotelItinerary = ({
  selectedLodging,
  checkIn,
  checkOut,
  chosenProduct,
  selectedPackageByLodging,
  setOpenChangeHotelModal,
  setOpenChangeRoomModal,
}: IReviewHotelItineraryProps) => {
  const { matchesMobile } = useDeviceTypes();

  if (!selectedPackageByLodging) return null;

  const mergedPolicies = useMemo(
    () =>
      [...(chosenProduct?.policies ?? [])].reduce(
        (uniquePolicies, currentPolicy) => {
          if (
            !uniquePolicies.some(({ title }) => currentPolicy.title === title)
          ) {
            uniquePolicies.push(currentPolicy);
          }
          return uniquePolicies;
        },
        [] as CheckInPolicy[]
      ),
    [chosenProduct?.policies]
  );

  const cancellationPolicyInfo = chosenProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(chosenProduct?.cancellationPolicy)
    : null;

  return (
    <Box
      className={clsx("review-pkg-hotel-itinerary", { mobile: matchesMobile })}
    >
      <Box className="review-itinerary-card-content-container hotel-summary-panel-section">
        <HotelSummaryPanel
          selectedLodging={selectedLodging}
          checkIn={checkIn}
          checkOut={checkOut}
          isMobile={matchesMobile}
          showCollectionBanner={false}
        />
        <Box className="change-ctas-container">
          <Button
            onClick={() => {
              console.log("go to hotel avail");
              setOpenChangeHotelModal(true);
            }}
          >
            <Typography className="change-cta-text">Change hotel</Typography>
          </Button>

          <Button
            onClick={() => {
              console.log("go to hotel shop");
              setOpenChangeRoomModal(true);
            }}
          >
            <Typography className="change-cta-text">Change room</Typography>
          </Button>
        </Box>
      </Box>

      {selectedLodging?.lodging?.checkInInstructions && checkIn && checkOut && (
        <Box
          className={clsx(
            "review-itinerary-card-content-container",
            "check-in-instructions"
          )}
        >
          <Icon name={IconName.CheckInIcon} />
          <Box className="text-container">
            <Typography
              variant="h2"
              className="hotel-shop-check-in-instructions-heading section-header"
            >
              {CHECK_IN_INSTRUCTIONS_TITLE}
            </Typography>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: formattedCheckIn(
                  checkIn,
                  selectedLodging.lodging.checkInInstructions?.checkInTime
                ),
              }}
            />
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: formattedCheckOut(
                  checkOut,
                  selectedLodging.lodging.checkInInstructions?.checkOutTime
                ),
              }}
            />
          </Box>
        </Box>
      )}
      {cancellationPolicyInfo ? (
        <Box
          className={clsx(
            "review-itinerary-card-content-container",
            "cancellation-details"
          )}
        >
          <Icon name={IconName.ErrorState} />

          <Box className="text-container">
            <Typography variant="h2" className="section-header">
              {cancellationPolicyInfo.primaryText}
            </Typography>
            <Typography variant="body2">
              {cancellationPolicyInfo.secondaryText}
            </Typography>
          </Box>
        </Box>
      ) : null}
      <HotelNeedToKnowPanel
        title={NEED_TO_KNOW_TITLE}
        subtitle={NEED_TO_KNOW_DETAILS}
        policies={mergedPolicies}
        fetchingPriceQuote={false}
      />
    </Box>
  );
};
