import React from "react";
import { Box } from "@material-ui/core";
import { RouteComponentProps } from "react-router-dom";
import "./styles.scss";
import { MobilePackagesBookWorkflowConnectorProps } from "./container";

export interface IMobilePackagesBookWorkflowProps
  extends MobilePackagesBookWorkflowConnectorProps,
    RouteComponentProps {}

export const MobilePackagesBookWorkflow =
  ({}: IMobilePackagesBookWorkflowProps) => {
    return (
      <Box className="mobile-packages-book-workflow-root">
        Mobile book workflow
      </Box>
    );
  };
