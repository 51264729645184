import React from "react";

export const TRAVELER_INFO_TITLE = "Step 1. Traveler Information";
export const TRAVELER_INFO_TITLE_UPDATED = "Select or Add Travelers";
export const TRAVELER_INFO_SUBTITLE = (
  <>
    <strong>
      The number of travelers you select cannot exceed the max occupancy of your
      chosen room.
    </strong>{" "}
    Make sure the information you add matches each traveler’s ID. You can’t
    change this information after booking.
  </>
);
export const FREQUENT_FLYER_TITLE = "Frequent Flyer Details";
export const FREQUENT_FLYER_SUBTITLE =
  "You can add an unlimited amount of Frequest Flyer programs to each traveler.";
export const ADDITIONAL_INFO_TITLE = "Additional Information";
export const ADULT_TITLE = "Adult";
export const CHILD_TITLE = "Child";
export const INFANT_SEAT_TITLE = "Infant";
export const INFANT_LAP_TITLE = "Infant";
export const UPDATE_TEXT = "Updating Passengers";
export const VALIDATE_TEXT = "Validating Passengers";
export const ADD_TRAVELERS_TEXT = "Add Travelers";
export const ADD_TRAVELERS_TEXT_UPDATED = "Add new Traveler";
export const EDIT_TRAVELER_TEXT = "Edit traveler";
export const ADD_TRAVELERS_SUBTITLE =
  "Ensure all information matches your travel ID. You can't change this information once you place a booking.";
export const ADD_TRAVELER_INFO_TEXT = "Add Traveler Information";
export const TRAVELER_INFO_TEXT = "Traveler Information";
export const ADD_TRAVELER_ERROR_MESSAGE =
  "Add or choose at least one traveler to continue.";
export const PASSPORT_TITLE = "Passport details";
export const PASSPORT_SUBTITLE =
  "Certain airlines may require you to submit your passport information on international flights.";
export const PASSPORT_SUBTITLE_2 =
  "All fields must be completed to be saved successfully.";

// corporate
export const COMPLETE_PROFILE_TITLE = "Complete traveler profile";
export const COMPLETE_PROFILE_SUBTITLE =
  "Please make sure all the information added matches the government issued ID for this traveler as this will be applied to travel bookings moving forward. Traveler information will be accessible to all Admins.";
