import React from "react";
import {
  FlightPassengerSelectors,
  FlightSelectors,
  GordianSeatSegment,
  SeatSelection,
  SeatSelectors,
  useCheckoutStateSelector,
  GordianSeatWithRewards,
  useCheckoutState,
  SeatEventTypes,
} from "@capone/checkout";
import { personToIPerson } from "../../../../utils/personToIPerson";
import { RouteComponentProps } from "react-router-dom";
import { PackagesSeatSelectionConnectorProps } from "./container";
import { convertUsdToAllRewards } from "../../../../api/v0/rewards/convertUsdToAllRewards";
import { TEvent } from "../../state/events";
import { PackagesMachineContext } from "../../state/types";
import { PATH_HOME } from "../../../../utils/paths";
import { ActionButton } from "halifax";
import { CONTINUE_CTA_TEXT } from "./textConstants";

export interface IPackagesSeatSelectionProps
  extends RouteComponentProps,
    PackagesSeatSelectionConnectorProps {}

export const PackagesSeatSelection = ({
  selectedRewardsAccountId,
  history,
}: IPackagesSeatSelectionProps) => {
  const [_state, send] = useCheckoutState<TEvent, PackagesMachineContext>();

  const tripDetails = useCheckoutStateSelector(
    FlightSelectors.getSelectedTripDetailsParent
  );
  const airportMap = useCheckoutStateSelector(FlightSelectors.getAirportMap);
  const passengers = useCheckoutStateSelector(
    FlightPassengerSelectors.getAllSelectedUserPassengersParent
  );

  const isLoading = useCheckoutStateSelector(
    SeatSelectors.getIsSeatSelectionLoading
  );
  const selectedSeats = useCheckoutStateSelector(
    SeatSelectors.getSelectedSeats
  );
  const cheapestSeat = useCheckoutStateSelector(SeatSelectors.getCheapestSeat);
  const seatMapHtml = useCheckoutStateSelector(SeatSelectors.getSeatMapHtml);
  const totalSeatPricing = useCheckoutStateSelector(
    SeatSelectors.getSeatTotalPricing
  );
  const skippedSeatSelection = useCheckoutStateSelector(
    SeatSelectors.getSkipSeatSelection
  );
  const seatMapAvailability = useCheckoutStateSelector(
    SeatSelectors.getSeatMapAvailability
  );
  const seatSlices = useCheckoutStateSelector(SeatSelectors.getSeatSlices);
  const seatSelectionErrorModalOpen = useCheckoutStateSelector(
    SeatSelectors.getSeatSelectionErrorOpen
  );
  const seatSelectionError = useCheckoutStateSelector(
    SeatSelectors.getSeatSelectionError
  );

  const handleSetSelectedSeats = (
    seats: GordianSeatWithRewards[],
    seatSegments: GordianSeatSegment[]
  ) => send({ type: SeatEventTypes.SELECT_SEATS, seats, seatSegments });

  const handleSkipSeatSelection = () =>
    send({ type: SeatEventTypes.SKIP_SEAT_SELECTION });

  const searchAgain = () => {
    history.push(PATH_HOME);
  };

  const handleContinue = () => {
    send({ type: SeatEventTypes.NEXT });
  };

  return (
    <>
      <SeatSelection
        tripDetails={tripDetails}
        airports={airportMap || {}}
        passengers={passengers.map(personToIPerson)}
        seatSelectionLoading={isLoading}
        selectedSeats={selectedSeats}
        cheapestSeat={cheapestSeat}
        seatMapHtml={seatMapHtml}
        totalSeatPricing={totalSeatPricing}
        skippedSeatSelection={skippedSeatSelection}
        seatMapAvailability={seatMapAvailability}
        selectedRewardsAccountId={selectedRewardsAccountId || undefined}
        seatSlices={seatSlices || []}
        seatSelectionErrorModalOpen={seatSelectionErrorModalOpen}
        seatSelectionError={seatSelectionError}
        setSelectedSeats={handleSetSelectedSeats}
        handleSearchAgain={searchAgain}
        handleSkipSeatSelection={handleSkipSeatSelection}
        convertUsdToAllRewards={convertUsdToAllRewards}
      />
      <ActionButton
        onClick={handleContinue}
        message={CONTINUE_CTA_TEXT}
        className="packages-continue-button"
        disabled={!selectedSeats.length && !skippedSeatSelection}
      />
    </>
  );
};
