import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { FlightBookPassengerSelection } from "./component";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type FlightBookPassengerSelectionConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedFlightBookPassengerSelection = connector(
  FlightBookPassengerSelection
);
