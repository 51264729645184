import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useScrollTrigger } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
  MobileFloatingButton,
  MobilePopoverCard,
  PickerType,
} from "halifax";
import queryStringParser from "query-string";
import React, { useEffect, useMemo } from "react";
import { RouteComponentProps } from "react-router";
import { PackageHotelShopStep } from "../../reducer/state";
// import { goToAvailability } from "../../utils/queryStringHelpers";
import { MobileShopHeaderConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../search/components/PackagesSearchControl/components";
import { goToAvailability } from "../../utils/queryStringHelpers";

export interface IMobileShopHeaderProps
  extends RouteComponentProps,
    MobileShopHeaderConnectorProps {
  onClickBackButton?: () => void;
  title?: string;
  // note: the value for `variant` cannot change, because it can affect hooks at runtime
  variant?: "custom" | "shop";
}

export const MobileShopHeader = (props: IMobileShopHeaderProps) => {
  const {
    title,
    origin,
    fromDate,
    untilDate,
    adultsCount,
    children,
    infants,
    stopsOption,
    selectedLodging,
    fetchPackageHotelShop,
    setDepartureDate,
    setReturnDate,
    history,
    hotelShopStep,
    setPackageHotelShopProgress,
    onClickBackButton,
    variant = "shop",
  } = props;
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [hasChangedDates, setHasChangedDates] = React.useState(false);
  const [from, setFrom] = React.useState<Date | null>(fromDate);
  const [until, setUntil] = React.useState<Date | null>(untilDate);

  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });

  const onClose = () => setOpenModal(false);

  const returnToAvailability = () => {
    goToAvailability({
      history,
      origin,
      lodging: selectedLodging,
      fromDate,
      untilDate,
      adultsCount,
      children,
      infants,
      stopsOption,
    });
  };

  if (variant === "shop") {
    useEffect(() => {
      setFrom(fromDate);
    }, [fromDate]);

    useEffect(() => {
      setUntil(untilDate);
    }, [untilDate]);

    useEffect(() => {
      const differentDates = from !== fromDate || until !== untilDate;
      const setBothDates = fromDate && untilDate;

      setHasChangedDates(differentDates && !!setBothDates);
    }, [fromDate, untilDate, from, until]);
  }

  const defaultOnClickBackButton = useMemo(() => {
    const { recommended } = queryStringParser.parse(history?.location?.search);

    if (hotelShopStep === PackageHotelShopStep.ChooseRoom) {
      return () => setPackageHotelShopProgress(PackageHotelShopStep.HotelInfo);
    }

    if (recommended) {
      return () => history.goBack();
    }

    return returnToAvailability;
  }, [hotelShopStep]);

  const MiddleSection = () => {
    switch (variant) {
      case "custom":
        return (
          <Box className={clsx("title-section", "header-middle-section")}>
            <Typography variant="body1" className="title">
              {title}
            </Typography>
          </Box>
        );
      case "shop":
      default:
        return (
          <>
            <Box
              className={clsx(
                "locations-and-dates-section",
                "header-middle-section"
              )}
            >
              {hotelShopStep === PackageHotelShopStep.HotelInfo ? (
                <>
                  <Typography variant="body1" className="locations-section">
                    {selectedLodging?.lodging.name}
                  </Typography>
                  <Box className="dates-and-occupancy-count-section">
                    <Typography variant="body2" className="dates-section">
                      {fromDate &&
                        untilDate &&
                        `${dayjs(fromDate).format("MMM DD")} - ${dayjs(
                          untilDate
                        ).format("MMM DD")}, `}
                      {adultsCount + children.length > 1
                        ? `${adultsCount + children.length} Travelers`
                        : `${adultsCount + children.length} Traveler`}
                    </Typography>
                  </Box>
                </>
              ) : (
                textConstants.CHOOSE_ROOM
              )}
            </Box>
            {hotelShopStep === PackageHotelShopStep.HotelInfo && (
              <Box className="edit-location-and-date-button-section">
                <ActionLink
                  className="edit-location-and-date-button"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  content={
                    <Icon name={IconName.B2BEditPencil} className="edit-icon" />
                  }
                />
              </Box>
            )}
          </>
        );
    }
  };

  return (
    <>
      <Box
        className={clsx("hotel-shop-progress-header-root global-mobile-nav", {
          scrolled: scrollTrigger,
        })}
      >
        <Box className="hotel-shop-progress-header-container">
          <Box className="go-back-button-section">
            <ActionLink
              className={clsx("go-back-button")}
              onClick={
                variant === "shop"
                  ? defaultOnClickBackButton
                  : onClickBackButton
              }
              content={<FontAwesomeIcon icon={faChevronLeft} />}
            />
          </Box>
          <MiddleSection />
        </Box>
      </Box>
      {variant === "shop" && (
        <MobilePopoverCard
          open={openModal}
          onClose={onClose}
          fullScreen={true}
          className="mobile-hotel-shop-header-root"
          contentClassName="mobile-shop-header-content-wrapper"
          headerElement={
            <Box className="modal-header-container">
              <Typography className="header-title">
                {textConstants.CHOOSE_DATES}
              </Typography>
              <ActionLink
                className="shop-header-close-button"
                content={<CloseButtonIcon />}
                label="Close"
                onClick={onClose}
              />
            </Box>
          }
        >
          <Box className="mobile-shop-header-content-container">
            <Box className="hotel-mobile-calendar-picker-root">
              <MonthAndDatePicker
                viewType={MonthAndDatePickerType.Column}
                startDate={from}
                endDate={until}
                setStartDate={setFrom}
                setEndDate={setUntil}
                className="b2b"
                pickerType={PickerType.RANGE}
              />
              {hasChangedDates && (
                <MobileFloatingButton
                  className="mobile-calendar-picker-search-button"
                  disabled={!(!!from && !!until)}
                  onClick={() => {
                    setDepartureDate(from);
                    setReturnDate(until);
                    fetchPackageHotelShop(history);
                  }}
                  wrapperClassName="b2b"
                >
                  Search dates
                </MobileFloatingButton>
              )}
            </Box>
          </Box>
        </MobilePopoverCard>
      )}
    </>
  );
};
