import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { MobilePackagesBookWorkflow } from "./component";
import { IStoreState } from "../../../../reducers/types";

export const mapStateToProps = (_: IStoreState) => {
  return {};
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobilePackagesBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobilePackagesBookWorkflow = withRouter(
  connector(MobilePackagesBookWorkflow)
);
