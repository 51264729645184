import { Box, Typography } from "@material-ui/core";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { PackagesDetailsPanelConnectorProps } from "./container";
import "./styles.scss";
import { Icon, IconName } from "halifax";
import {
  FlightSelectors,
  useCheckoutStateSelector,
  LodgingSelectors,
} from "@capone/checkout";
import dayjs from "dayjs";

export interface IPackagesDetailsPanelProps
  extends RouteComponentProps,
    PackagesDetailsPanelConnectorProps {}

export const PackageDetailsPanel = ({}: IPackagesDetailsPanelProps) => {
  const selectedLodging = useCheckoutStateSelector(
    LodgingSelectors.getSelectedLodging
  );
  const selectedRoom = useCheckoutStateSelector(
    LodgingSelectors.getSelectedRoom
  );
  const selectedRoomProduct = useCheckoutStateSelector(
    LodgingSelectors.getSelectedRoomProduct
  );
  const fromDate = useCheckoutStateSelector(LodgingSelectors.getHotelFromDate);
  const untilDate = useCheckoutStateSelector(
    LodgingSelectors.getHotelUntilDate
  );
  const guestCount = useCheckoutStateSelector(LodgingSelectors.getGuestCount);

  const tripDetails = useCheckoutStateSelector(FlightSelectors.getTripDetails);

  const outgoingSlice = tripDetails.slices?.find((slice) => slice.outgoing);
  const returnSlice = tripDetails.slices?.find((slice) => !slice.outgoing);

  return (
    <Box className="package-details-panel-root">
      <Box className="package-details-panel-section">
        <Box className="package-details-panel-section-icon-wrapper">
          <Box className="icon-circle">
            <Icon name={IconName.HotelFunnelIcon} />
          </Box>
        </Box>
        <Box className="package-details-panel-section-details-wrapper">
          <Typography className="package-details-panel-section-name">
            <strong>
              {selectedLodging?.lodging.name}, {selectedLodging?.lodging.city}
            </strong>
          </Typography>
          <Box className="package-details-panel-section-details-row">
            <Typography>{selectedRoom?.roomInfo.name}</Typography> |{" "}
            <Typography>
              Room fits ({selectedRoomProduct?.bedTypes.occupancy}) guest
              {selectedRoomProduct?.bedTypes.occupancy === 1 ? "" : "s"}
            </Typography>{" "}
            |{" "}
            <Typography>
              <strong>Check-in:</strong> {dayjs(fromDate).format("ddd, MMM D")}
            </Typography>{" "}
            |{" "}
            <Typography>
              <strong>Checkout:</strong> {dayjs(untilDate).format("ddd, MMM D")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box className="package-details-section-separator">
        <Box className="icon-circle">
          <span>+</span>
        </Box>
      </Box>
      <Box className="package-details-panel-section">
        <Box className="package-details-panel-section-icon-wrapper">
          <Box className="icon-circle">
            <Icon name={IconName.FlightFunnelIcon} />
          </Box>
        </Box>
        <Box className="package-details-panel-section-details-wrapper">
          <Typography className="package-details-panel-section-name">
            <strong>{outgoingSlice?.originName}</strong> (
            {outgoingSlice?.originCode}) to{" "}
            <strong>{outgoingSlice?.destinationName}</strong> (
            {outgoingSlice?.destinationCode})
          </Typography>
          <Box className="package-details-panel-section-details-row">
            <Typography>
              {guestCount} traveler{guestCount === 1 ? "" : "s"}
            </Typography>{" "}
            | <Typography>Round-trip flight</Typography> |{" "}
            <Typography>
              <strong>Outbound:</strong>{" "}
              {dayjs(outgoingSlice?.departureTime).format("ddd, MMM D")}
            </Typography>{" "}
            |{" "}
            <Typography>
              <strong>Return:</strong>{" "}
              {dayjs(returnSlice?.departureTime).format("ddd, MMM D")}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
