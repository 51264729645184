import { Box, Typography } from "@material-ui/core";
import {
  ButtonWrap,
  FareClassInfoModal,
  Icon,
  IconName,
  MultiSelectDropdown,
} from "halifax";
import React from "react";

import clsx from "clsx";
import "./styles.scss";
import * as textConstants from "./textConstants";
import { FareclassOptionFilter } from "redmond";

import { FARE_CLASSES } from "../../../../../search/components/PackagesSearchControl/components/FareclassOptionSelection/textConstants";

export interface IFareclassOptionSelectionProps {
  fareclassOptionFilter: FareclassOptionFilter;
  setFareclassOptionFilter: (
    fareclassOptionFilter: FareclassOptionFilter
  ) => void;
  hasSetFareclassFilter?: boolean;
  showDropdownContentOnly?: boolean;
  appliedLabel?: string | number;
  icon?: IconName;
  title?: string;
  optionLabelPlacement?: "start" | "end";
  labelIcon?: IconName;
  includeClearFilter?: boolean;
  popoverClassName?: string;
  includeFareClassInfoModal?: boolean;
  isMobile?: boolean;
}

const FARE_CLASS_VALUES: Record<string, number> = {
  basic: 1,
  standard: 2,
  enhanced: 3,
  premium: 4,
  luxury: 5,
};

export const FareclassOptionSelection = ({
  fareclassOptionFilter,
  setFareclassOptionFilter,
  hasSetFareclassFilter,
  showDropdownContentOnly,
  appliedLabel,
  icon,
  title,
  optionLabelPlacement,
  labelIcon,
  includeClearFilter = true,
  popoverClassName,
  includeFareClassInfoModal,
  isMobile,
}: IFareclassOptionSelectionProps) => {
  const [fareclassInfoModalOpen, setFareclassInfoModalOpen] =
    React.useState<boolean>(false);

  const options = Object.keys(fareclassOptionFilter)
    .map((fareclass) => ({
      value: fareclass,
      label: FARE_CLASSES[fareclass],
    }))
    .sort((a, b) => FARE_CLASS_VALUES[a.value] - FARE_CLASS_VALUES[b.value]);

  const handleChange = (selectedValues: string[]) => {
    setFareclassOptionFilter({
      ...Object.keys(fareclassOptionFilter).reduce(
        (prev, curr) => ({ ...prev, [curr]: false }),
        {} as FareclassOptionFilter
      ),
      ...selectedValues.reduce(
        (prev, curr) => ({ ...prev, [curr]: true }),
        {} as FareclassOptionFilter
      ),
    });
  };

  const reset = () => {
    setFareclassOptionFilter(
      Object.keys(fareclassOptionFilter).reduce(
        (prev, curr) => ({ ...prev, [curr]: false }),
        {} as FareclassOptionFilter
      )
    );
  };

  return (
    <Box className="flight-shop-fareclass-filter-container" tabIndex={0}>
      {!!title && showDropdownContentOnly && includeFareClassInfoModal ? (
        <>
          <ButtonWrap
            className="header-container modal-button-wrapper"
            onClick={() => setFareclassInfoModalOpen(true)}
            aria-label="Fare class info modal button"
          >
            {icon && <Icon name={icon} />}
            <Typography className="header-label">{title}</Typography>
            <Icon name={IconName.InfoCircle} />
          </ButtonWrap>

          <FareClassInfoModal
            open={fareclassInfoModalOpen}
            setOpen={setFareclassInfoModalOpen}
            isMobile={isMobile}
          />
        </>
      ) : (
        <Box className="header-container">
          {icon && <Icon name={icon} />} {title}
        </Box>
      )}
      <MultiSelectDropdown
        toolTipContent={
          <>
            <ButtonWrap
              className="header-container modal-button-wrapper"
              onClick={() => setFareclassInfoModalOpen(true)}
              aria-label="Fare class info modal button"
            >
              <Typography variant="body1" className="tooltip-text">
                {textConstants.LEARN_ABOUT_FARE_CLASSES}
              </Typography>
              {icon && <Icon name={icon} />}
              <Icon name={IconName.InfoCircle} />
            </ButtonWrap>

            <FareClassInfoModal
              open={fareclassInfoModalOpen}
              setOpen={setFareclassInfoModalOpen}
              isMobile={isMobile}
            />
          </>
        }
        currentValue={Object.keys(fareclassOptionFilter).filter(
          (fareclass) => fareclassOptionFilter[fareclass]
        )}
        dropdownLabel={
          <>
            {labelIcon && <Icon name={labelIcon} />}
            {textConstants.FARECLASS_DROPDOWN_LABEL(
              hasSetFareclassFilter ? appliedLabel : undefined
            )}
          </>
        }
        options={options}
        setValue={handleChange}
        showDropdownContentOnly={showDropdownContentOnly}
        className={clsx("b2b-shop-filter", {
          "has-value": hasSetFareclassFilter,
        })}
        popoverClassName={clsx(
          "b2b",
          "flight-availability-airlines-popover",
          popoverClassName,
          "filter-experiment"
        )}
        dropdownIcon={
          includeClearFilter && hasSetFareclassFilter ? (
            <ButtonWrap
              onClick={(e) => {
                e.stopPropagation();
                reset();
              }}
            >
              <Icon name={IconName.XCircle} />
            </ButtonWrap>
          ) : undefined
        }
        optionLabelPlacement={optionLabelPlacement}
        anchorOrigin={(() => {
          return {
            vertical: "bottom",
            horizontal: "left",
          };
        })()}
        transformOrigin={(() => {
          return {
            vertical: "top",
            horizontal: "left",
          };
        })()}
      />
    </Box>
  );
};
