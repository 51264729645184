import { Box, Divider } from "@material-ui/core";
import {
  ActionLink,
  CloseButtonIcon,
  IconName,
  MobileFloatingButton,
  MobilePopoverCard,
} from "halifax";
import React from "react";
import {
  AirlineCode,
  Airport,
  FareclassOptionFilter,
  FlightSortOption,
  IFlightNumberFilter,
  ISortOptions,
  ITimeRange,
  ITripTerminus,
  RegionType,
  SliceStopCountFilter,
} from "redmond";
import { IAirlineOptions, IFlightShopFilterSelector } from "../../../reducer";
import {
  AIRLINE_SELECTION_SUBTITLE,
  FILTER_MODAL_TITLES,
  FLIGHT_NUMBER_SELECTION_SUBTITLE,
} from "../textConstants";
import { FlightShopSortAndFilterType } from "../../../../../utils/sortAndFilter";

import { AirlineFilterSelection } from "../../FlightShopFilters/components/AirlineFilterSelection";
import { AirportFilterSelection } from "../../FlightShopFilters/components/AirportFilterSelection";
import { DepartureArrivalSelectionDropdown } from "../../FlightShopFilters/components/DepartureArrivalSelectionDropdown";
import { DurationSelection } from "../../FlightShopFilters/components/DurationSelection";
import { FareclassOptionSelection } from "../../FlightShopFilters/components/FareclassOptionSelection";
import { FlightNumberFilterSelection } from "../../FlightShopFilters/components/FlightNumberFilterSelection";
import { MaxPriceFilterSelection } from "../../FlightShopFilters/components/MaxPriceFilterSelection";
import { StopsOptionSelection } from "../../FlightShopFilters/components/StopsOptionSelection";
import { SortOptionSelection } from "../../SortOptionSelection";
import "./styles.scss";

interface IMobileAllFiltersModalProps {
  openAllFiltersModal: boolean;
  setOpenAllFiltersModal: (arg: boolean) => void;
  sortOptionsToDisplay: ISortOptions[];
  filtersToDisplay: FlightShopSortAndFilterType[];
  handleApply: () => void;
  handleReset: () => void;
  readyToReset: boolean;
  optionsChanged: boolean;
  sortOption: FlightSortOption;
  setSortOption: (option: FlightSortOption) => void;
  stopsOption: SliceStopCountFilter;
  setStopsOption: (stopsOption: SliceStopCountFilter) => void;
  airlineFilter: AirlineCode[];
  setAirlineFilter: (airlineFilter: AirlineCode[]) => void;
  allAirlines: IAirlineOptions[];
  flightShopFilters: IFlightShopFilterSelector;
  maxPriceFilter: number;
  setMaxPriceFilter: (maxPrice: number) => void;
  durationFilter: number;
  setDurationFilter: (duration: number) => void;
  minDuration: number;
  maxDuration: number;
  filteredFlightCount: number;
  origin: ITripTerminus | null;
  outboundDepartureTimeRange: ITimeRange;
  outboundArrivalTimeRange: ITimeRange;
  returnDepartureTimeRange: ITimeRange;
  returnArrivalTimeRange: ITimeRange;
  setOutboundDepartureTimeRange: (
    outboundDepartureTimeRange: ITimeRange
  ) => void;
  setOutboundArrivalTimeRange: (outboundArrivalTimeRange: ITimeRange) => void;
  setReturnDepartureTimeRange: (returnDepartureTimeRange: ITimeRange) => void;
  setReturnArrivalTimeRange: (returnArrivalTimeRange: ITimeRange) => void;
  // destination: ITripTerminus | null;
  originAirport?: Airport;
  destinationAirport?: Airport;
  airportFilter: string[];
  setAirportFilter: (airportFilter: string[]) => void;
  flightNumberFilter: IFlightNumberFilter[];
  setFlightNumberFilter: (flightNumbers: IFlightNumberFilter[]) => void;
  flightNumberAirlineFilter: string;
  setFlightNumberAirlineFilter: (airline: string) => void;
  departureDateString: string;
  fareclassOptionFilter: FareclassOptionFilter;
  setFareclassOptionFilter: (
    fareclassOptionFilter: FareclassOptionFilter
  ) => void;
  filtersToApplyMatchDefaultState?: boolean;
  filtersToApplyCount: number;
}
export const MobileAllFiltersModal = (props: IMobileAllFiltersModalProps) => {
  const {
    openAllFiltersModal,
    setOpenAllFiltersModal,
    filtersToDisplay,
    sortOptionsToDisplay,
    handleApply,
    handleReset,
    optionsChanged,
    readyToReset,
    sortOption,
    setSortOption,
    stopsOption,
    setStopsOption,
    airlineFilter,
    setAirlineFilter,
    flightShopFilters,
    maxPriceFilter,
    setMaxPriceFilter,
    durationFilter,
    setDurationFilter,
    minDuration,
    maxDuration,
    filteredFlightCount,
    outboundDepartureTimeRange,
    outboundArrivalTimeRange,
    returnDepartureTimeRange,
    returnArrivalTimeRange,
    setOutboundDepartureTimeRange,
    setOutboundArrivalTimeRange,
    setReturnDepartureTimeRange,
    setReturnArrivalTimeRange,
    origin,
    // destination,
    originAirport,
    // destinationAirport,
    airportFilter,
    setAirportFilter,
    allAirlines,
    flightNumberFilter,
    setFlightNumberFilter,
    flightNumberAirlineFilter,
    setFlightNumberAirlineFilter,
    departureDateString,
    fareclassOptionFilter,
    setFareclassOptionFilter,
    filtersToApplyMatchDefaultState,
    filtersToApplyCount,
  } = props;
  const { airportOptions, flightNumbersByAirline } = flightShopFilters;

  const filtersToElementMap = {
    [FlightShopSortAndFilterType.Sort]: (
      <SortOptionSelection
        sortOptions={sortOptionsToDisplay}
        sortOption={sortOption}
        setSortOption={setSortOption}
        showDropdownContentOnly
        title={FILTER_MODAL_TITLES.sortText}
        icon={IconName.Sort}
      />
    ),
    [FlightShopSortAndFilterType.Fareclass]: (
      <FareclassOptionSelection
        fareclassOptionFilter={fareclassOptionFilter}
        setFareclassOptionFilter={setFareclassOptionFilter}
        showDropdownContentOnly
        title={FILTER_MODAL_TITLES.fareclassText}
        icon={IconName.FareIcon}
        includeFareClassInfoModal
        isMobile
      />
    ),

    [FlightShopSortAndFilterType.NumberOfStops]: (
      <StopsOptionSelection
        stopsOption={stopsOption}
        setStopsOption={setStopsOption}
        showDropdownContentOnly
        title={FILTER_MODAL_TITLES.numOfStopsText}
        icon={IconName.StopsFilterIcon}
      />
    ),
    [FlightShopSortAndFilterType.Airline]: (
      <AirlineFilterSelection
        flightShopFilters={flightShopFilters}
        airlineFilter={airlineFilter}
        setAirlineFilter={setAirlineFilter}
        showDropdownContentOnly
        title={FILTER_MODAL_TITLES.airlineText}
        icon={IconName.DiagonalBlueAirplane}
        optionLabelPlacement="start"
      />
    ),
    // TODO: Instead of adding the condition here, the caller should pass the right set of filters to show.

    [FlightShopSortAndFilterType.OutboundTimes]: (
      <DepartureArrivalSelectionDropdown
        outboundDepartureTimeRange={outboundDepartureTimeRange}
        outboundArrivalTimeRange={outboundArrivalTimeRange}
        returnDepartureTimeRange={returnDepartureTimeRange}
        returnArrivalTimeRange={returnArrivalTimeRange}
        setOutboundDepartureTimeRange={setOutboundDepartureTimeRange}
        setOutboundArrivalTimeRange={setOutboundArrivalTimeRange}
        setReturnDepartureTimeRange={setReturnDepartureTimeRange}
        setReturnArrivalTimeRange={setReturnArrivalTimeRange}
        showDropdownContentOnly
        icon={IconName.ClockIconThin}
        outboundTitle={FILTER_MODAL_TITLES.outboundTimesText}
        returnTitle={FILTER_MODAL_TITLES.returnTimesText}
        origin={origin}
        // destination={destination}
        originAirport={originAirport}
        // destinationAirport={destinationAirport}
      />
    ),
    [FlightShopSortAndFilterType.MaxPrice]: (
      <MaxPriceFilterSelection
        flightShopFilters={flightShopFilters}
        maxPriceFilter={maxPriceFilter}
        setMaxPriceFilter={setMaxPriceFilter}
        title={FILTER_MODAL_TITLES.priceRangeText}
        subtitle={FILTER_MODAL_TITLES.priceRangeSubtitleText}
        icon={IconName.MoneyOutlineThin}
        showDropdownContentOnly
      />
    ),
    [FlightShopSortAndFilterType.Duration]: (
      <DurationSelection
        durationFilter={durationFilter}
        setDurationFilter={setDurationFilter}
        minDuration={minDuration}
        maxDuration={maxDuration}
        flightCount={filteredFlightCount}
        showDropdownContentOnly
        isMobile
      />
    ),
    // TODO: Instead of adding the condition here, the caller should pass the right set of filters to show.
    [FlightShopSortAndFilterType.Airport]:
      origin?.id.code.regionType !== RegionType.Airport ? (
        <AirportFilterSelection
          allAirports={airportOptions}
          airportFilter={airportFilter}
          setAirportFilter={setAirportFilter}
          showDropdownContentOnly
          title={FILTER_MODAL_TITLES.outboundAirportText}
          icon={IconName.DiagonalBlueAirplane}
          optionLabelPlacement="start"
          displayLabelAndValue
        />
      ) : null,
    [FlightShopSortAndFilterType.FlightNumber]: (
      <FlightNumberFilterSelection
        showDropdownContentOnly
        allAirlines={allAirlines}
        flightNumbersByAirline={flightNumbersByAirline}
        flightNumberFilter={flightNumberFilter}
        departureDateString={departureDateString}
        setFlightNumberFilter={setFlightNumberFilter}
        useAirlineCheckbox
        title={FILTER_MODAL_TITLES.flightNumberText}
        icon={IconName.DiagonalBlueAirplane}
        flightNumberAirlineFilter={flightNumberAirlineFilter}
        setFlightNumberAirlineFilter={setFlightNumberAirlineFilter}
        airlineSubtitle={AIRLINE_SELECTION_SUBTITLE}
        flightNumberSubtitle={FLIGHT_NUMBER_SELECTION_SUBTITLE}
      />
    ),
  };

  return (
    <MobilePopoverCard
      fullScreen
      open={openAllFiltersModal}
      onClose={() => setOpenAllFiltersModal(false)}
      className={"mobile-pkg-flight-shop-all-filters-modal"}
      contentClassName={"mobile-pkg-flight-shop-search-filter-wrapper"}
      bottomButton={
        <MobileFloatingButton
          className="apply-filters-floating-button"
          wrapperClassName="apply-filters-floating-button-container"
          disabled={!optionsChanged}
          onClick={() => {
            handleApply();
            setOpenAllFiltersModal(false);
          }}
        >
          {filtersToApplyMatchDefaultState || filtersToApplyCount === 0
            ? FILTER_MODAL_TITLES.applyText
            : FILTER_MODAL_TITLES.applyFiltersText(filtersToApplyCount)}
        </MobileFloatingButton>
      }
      topRightButton={
        <ActionLink
          className="filter-modal-close-button"
          content={<CloseButtonIcon />}
          label="Close"
          onClick={() => setOpenAllFiltersModal(false)}
        />
      }
      topLeftButton={
        readyToReset ? (
          <ActionLink
            content={FILTER_MODAL_TITLES.clearAllFiltersText}
            onClick={handleReset}
          />
        ) : undefined
      }
    >
      {" "}
      {filtersToDisplay
        .map((filter) => {
          return { filter: filter, element: filtersToElementMap[filter] };
        })
        .filter(({ element }) => element !== null)
        .map(({ filter, element }, index, { length }) => (
          <Box key={filter} className="mobile-pkg-flight-shop-filter-wrapper">
            {element}
            {index !== length - 1 && <Divider className="filter-divider" />}
          </Box>
        ))}
    </MobilePopoverCard>
  );
};
