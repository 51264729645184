import { Box, Button, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { getReviewCardHeader } from "./constants";
import {
  AirlineIcon,
  ExpandableCard,
  ExpandableCardContent,
  FlightCombinationBanner,
  FlightSummaryRow,
  formatInterval,
  getTotalPriceText,
  MixedCabinToolTip,
  removeTimezone,
  useDeviceTypes,
} from "halifax";
import dayjs from "dayjs";
import { RouteComponentProps } from "react-router-dom";
import { ReviewFlightItineraryConnectorProps } from "./container";
import * as constants from "./constants";
import { Airport, FareDetails, FlightShopStep, TripDetails } from "redmond";
import {
  airlinesCountTripSegment,
  getSliceIndex,
} from "../../../flight-shop/components/FlightList/components/FlightDetails/component";
import "./styles.scss";
import { ReviewFlightDetails } from "./components/ReviewFlightDetails";
import H from "history";
import clsx from "clsx";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MobileItineraryDetailsModal } from "./components/MobileItineraryDetailsModal";

enum OpenModalEnum {
  DEPARTURE = "DEPARTURE",
  RETURN = "RETURN",
}
export type IOpenModal = OpenModalEnum | false;

interface IReviewFlightItineraryProps
  extends ReviewFlightItineraryConnectorProps,
    RouteComponentProps {
  setOpenChangeOutboundFlightModal: (arg: boolean) => void;
}

export const ReviewFlightItinerary = ({
  selectedPackageByLodging,
  airports,
  tripDetails,
  departureDate,
  returnDate,
  setPackagesFlightShopProgress,
  fareDetails,
  setOpenChangeOutboundFlightModal,
  history,
  isMultiTicket,
}: IReviewFlightItineraryProps) => {
  const [isOutgoingMixedClass, setIsOutgoingMixedClass] = useState(false);
  const [isReturnMixedClass, setIsReturnMixedClass] = useState(false);
  const [expandedCardKey, setExpandedCardKey] = useState<string>("");
  const [openModal, setOpenModal] = useState<IOpenModal>(false);
  const { matchesMobile } = useDeviceTypes();

  useEffect(() => {
    if (fareDetails) {
      setIsOutgoingMixedClass(constants.getIsMixedClass(fareDetails.slices[0]));

      setIsReturnMixedClass(constants.getIsMixedClass(fareDetails.slices[1]));
    }
  }, [fareDetails]);

  if (!selectedPackageByLodging) return null;

  const handleCardKeyChange = (cardKey: string) => {
    setExpandedCardKey(cardKey === expandedCardKey ? "" : cardKey);
  };

  const renderReviewItineraryCards = () => {
    return (
      <>
        {departureDate && fareDetails && (
          <Box className="review-flight-itinerary outbound">
            <Typography className="card-header">
              {renderCardHeader(
                getReviewCardHeader(
                  true,
                  airports[tripDetails.slices[0].destinationCode]
                    ? airports[tripDetails.slices[0].destinationCode].cityName
                    : tripDetails.slices[0].destinationName,
                  dayjs(
                    removeTimezone(tripDetails.slices[0].departureTime)
                  ).toDate(),
                  true
                )
              )}
            </Typography>
            {isOutgoingMixedClass && <MixedCabinToolTip />}
            <ExpandableCard
              className="review-itinerary-card"
              isMobile={matchesMobile}
              expandedCardKey={expandedCardKey}
              cardKey={constants.DEPARTURE_KEY}
              handleCardKeyChange={() => {
                handleCardKeyChange(constants.DEPARTURE_KEY);
              }}
              content={getExpandableCardContent({
                tripDetails,
                fareDetails,
                isMobile: matchesMobile,
                sliceDate: departureDate,
                sliceIndex: 0,
                setPackagesFlightShopProgress,
                airports,
                setOpenChangeFlightModal: setOpenChangeOutboundFlightModal,
                isMixedCabinClass: isOutgoingMixedClass,
                history,
                // setOpenMultipleAirlinesFares,
              })}
            />
          </Box>
        )}
        {returnDate && fareDetails && (
          <Box className="review-flight-itinerary return">
            <Typography className="card-header">
              {renderCardHeader(
                getReviewCardHeader(
                  false,
                  airports[tripDetails.slices[1].destinationCode]
                    ? airports[tripDetails.slices[1].destinationCode].cityName
                    : tripDetails.slices[1].destinationName,
                  dayjs(
                    removeTimezone(tripDetails.slices[1].departureTime)
                  ).toDate(),
                  true
                )
              )}
              {isReturnMixedClass && <MixedCabinToolTip />}
            </Typography>
            <ExpandableCard
              className="review-itinerary-card"
              isMobile={matchesMobile}
              expandedCardKey={expandedCardKey}
              cardKey={constants.RETURN_KEY}
              handleCardKeyChange={() => {
                handleCardKeyChange(constants.RETURN_KEY);
              }}
              content={getExpandableCardContent({
                tripDetails,
                fareDetails,
                isMobile: matchesMobile,
                sliceDate: returnDate,
                sliceIndex: 1,
                setPackagesFlightShopProgress,
                airports,
                isMixedCabinClass: isReturnMixedClass,
                history,
                // setOpenMultipleAirlinesFares,
              })}
            />
          </Box>
        )}
      </>
    );
  };
  const renderMobileReviewItineraryCards = () => {
    return (
      <Box className="mobile-flight-itinerary-cards-section">
        {departureDate && fareDetails && (
          <Box className="mobile-review-flight-itinerary outbound">
            {renderMobileFlightSummaryRow(
              true,
              tripDetails,
              departureDate,
              () => {
                setOpenModal(OpenModalEnum.DEPARTURE);
              },
              airports,
              isOutgoingMixedClass,
              history,
              setPackagesFlightShopProgress
            )}
          </Box>
        )}
        {returnDate && fareDetails && (
          <Box className="mobile-review-flight-itinerary return">
            {renderMobileFlightSummaryRow(
              false,
              tripDetails,
              returnDate,
              () => {
                setOpenModal(OpenModalEnum.RETURN);
              },

              airports,
              isReturnMixedClass,
              history,
              setPackagesFlightShopProgress
            )}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      {isMultiTicket && (
        <FlightCombinationBanner
          className={clsx("review-itinerary-flight-combo-banner", "b2b")}
          description={constants.COMBINATION_FLIGHT_WARNING}
          toolTipCopy={constants.COMBINATION_FLIGHT_TOOLTIP}
        />
      )}
      {matchesMobile
        ? renderMobileReviewItineraryCards()
        : renderReviewItineraryCards()}

      <MobileItineraryDetailsModal
        openModal={openModal !== false}
        isDeparture={openModal === OpenModalEnum.DEPARTURE}
        tripDetails={tripDetails}
        fareDetails={fareDetails}
        onClose={() => setOpenModal(false)}
        isMixedCabinClass={
          openModal === OpenModalEnum.DEPARTURE
            ? isOutgoingMixedClass
            : isReturnMixedClass
        }
      />
    </>
  );
};

const getExpandableCardContent = (args: {
  tripDetails: TripDetails;
  fareDetails: FareDetails;
  isMobile: boolean;
  sliceDate: Date;
  sliceIndex: number;
  setPackagesFlightShopProgress: (progress: FlightShopStep) => void;
  airports: { [key: string]: Airport };
  setOpenChangeFlightModal?: (arg: boolean) => void;
  isMixedCabinClass: boolean;
  history: H.History;
  // setOpenMultipleAirlinesFares: (value: boolean) => void;
}) => {
  const {
    tripDetails,
    fareDetails,
    isMobile,
    sliceDate,
    sliceIndex,
    setPackagesFlightShopProgress,
    airports,
    setOpenChangeFlightModal,
    isMixedCabinClass,
    history,

    // setOpenMultipleAirlinesFares,
  } = args;

  const slice = tripDetails.slices[sliceIndex];

  const expandedTitle = renderExpandedTitle(
    getReviewCardHeader(
      sliceIndex === 0,
      airports[slice.destinationCode]
        ? airports[slice.destinationCode].cityName
        : slice.destinationName,
      sliceDate,
      true
    ),
    sliceIndex,
    fareDetails,
    setPackagesFlightShopProgress
  );

  const cardContent: ExpandableCardContent = {
    title: renderDesktopFlightSummaryRow(
      sliceIndex,
      tripDetails,
      fareDetails,
      isMobile,
      history,
      setPackagesFlightShopProgress,
      setOpenChangeFlightModal
    ),
    expandedTitle,
    body: (
      <ReviewFlightDetails
        isOutgoing={sliceIndex === 0}
        fareDetails={fareDetails}
        isMixedCabinClass={isMixedCabinClass}

        // setOpenMultipleAirlinesFares={setOpenMultipleAirlinesFares}
      />
    ),
  };

  return cardContent;
};

const renderExpandedTitle = (
  expandedTitle: string,
  sliceIndex: number,
  fareDetails: FareDetails,
  setFlightShopProgress: (progress: FlightShopStep) => void
) => {
  const onClick = () => {
    const flightShopStep =
      sliceIndex === 0
        ? FlightShopStep.ChooseDeparture
        : FlightShopStep.ChooseReturn;
    return (setFlightShopProgress as (progress: FlightShopStep) => void)(
      flightShopStep
    );
  };
  return (
    <Box className="review-itinerary-expanded-title-wrapper">
      <Typography className="review-itinerary-expanded-title">
        {renderCardHeader(expandedTitle)}
        {constants.getIsMixedClass(fareDetails.slices[sliceIndex]) && (
          <MixedCabinToolTip />
        )}
      </Typography>
      <div className="review-itinerary-expanded-title-actions-wrapper">
        <Button
          className="review-itinerary-change-button b2b"
          onClick={onClick}
        >
          {constants.CHANGE}
        </Button>
        <span className="view-details">View details</span>
      </div>
    </Box>
  );
};

const renderCardHeader = (header: string, isMulticity?: boolean) => {
  const [fromHeader, dateHeader] = header.split(isMulticity ? "-" : ":");
  return (
    <>
      <span className="from">{fromHeader}</span>
      <span className="date">{dateHeader}</span>
    </>
  );
};

const renderDesktopFlightSummaryRow = (
  tripSliceIndex: number,
  tripDetails: TripDetails,
  fareDetails: FareDetails,
  isMobile: boolean,

  history: H.History,
  setPackagesFlightShopProgress: (progress: FlightShopStep) => void,
  setOpenChangeFlightModal?: (arg: boolean) => void
) => {
  const sliceIndex = getSliceIndex(tripSliceIndex === 0, fareDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  const firstTripSegment = tripSlice.segmentDetails[0];

  const fareDetailsSlice = fareDetails.slices[sliceIndex];
  const paxPricing = fareDetailsSlice?.paxPricings
    ? fareDetailsSlice.paxPricings[0]
    : undefined;

  const airlinesCount = airlinesCountTripSegment(tripSlice.segmentDetails);

  return (
    <>
      <FlightSummaryRow
        className="review-pkg-itinerary-flight-summary"
        airlineCode={firstTripSegment.airlineCode}
        airline={firstTripSegment.airlineName}
        fareClass={fareDetailsSlice.fareShelf?.brandName ?? ""}
        airlinesCount={airlinesCount}
        departureTime={dayjs(removeTimezone(tripSlice.departureTime)).format(
          "h:mm A"
        )}
        arrivalTime={dayjs(removeTimezone(tripSlice.arrivalTime)).format(
          "h:mm A"
        )}
        departureCode={tripSlice.originCode}
        arrivalCode={tripSlice.destinationCode}
        duration={formatInterval(
          dayjs(tripSlice.arrivalTime).diff(
            dayjs(tripSlice.departureTime),
            "minute",
            true
          )
        )}
        numStops={tripSlice.stops}
        layoverString={constants.createStopoverString(tripSlice) || ""}
        bestFlightText={
          fareDetailsSlice.fareScore?.isBest
            ? constants.bestFlightText
            : undefined
        }
        cheapestFlightText={
          fareDetailsSlice.fareScore?.isCheapest
            ? constants.cheapestFlightText
            : undefined
        }
        bestQualityText={
          fareDetailsSlice.fareScore?.isBestQuality
            ? constants.bestQualityText
            : undefined
        }
        fastestText={
          fareDetailsSlice.fareScore?.isFastest
            ? constants.fastestText
            : undefined
        }
        price={
          paxPricing
            ? getTotalPriceText({ price: paxPricing.pricing.baseAmount.fiat })
            : ""
        }
        isMobile={isMobile}
        flightNumber={firstTripSegment.flightNumber}
      />
      <Button
        className="review-itinerary-change-button b2b"
        onClick={() => {
          setOpenChangeFlightModal
            ? setOpenChangeFlightModal(true)
            : setPackagesFlightShopProgress(FlightShopStep.ChooseReturn);
          history.goBack();
        }}
      >
        {constants.CHANGE}
      </Button>
      {!isMobile && (
        <span className="view-details">{constants.VIEW_DETAILS}</span>
      )}
    </>
  );
};

const renderMobileFlightSummaryRow = (
  isDeparture: boolean,
  tripDetails: TripDetails,
  date: Date,
  setOpen: () => void,
  airports: { [key: string]: Airport },
  isMixedClass: boolean,
  history: H.History,
  setPackagesFlightShopProgress: (progress: FlightShopStep) => void,
  setOpenChangeFlightModal?: (arg: boolean) => void
) => {
  const sliceIndex = getSliceIndex(isDeparture, tripDetails);
  const tripSlice = tripDetails.slices[sliceIndex];
  const firstTripSegment = tripSlice.segmentDetails[0];
  const airlinesCount = airlinesCountTripSegment(tripSlice.segmentDetails);
  const { type, description } = constants.getReviewCardHeaderWithType(
    isDeparture,
    airports[tripSlice.destinationCode]
      ? airports[tripSlice.destinationCode].cityName
      : tripSlice.destinationName,
    date
  );

  return (
    <Box className="airline-details-with-chevron" onClick={setOpen}>
      <Box className="airline-details-with-title">
        <Typography className="card-header">
          <span className="bold">{type}</span>
          <span
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          ></span>
          {((isDeparture && isMixedClass) ||
            (!isDeparture && isMixedClass)) && <MixedCabinToolTip />}
        </Typography>
        <Box className="airline-details">
          <Box className="airline-details-left-container">
            <Typography variant="body1">
              {`${dayjs(removeTimezone(tripSlice.departureTime)).format(
                "h:mm A"
              )} - ${dayjs(removeTimezone(tripSlice.arrivalTime)).format(
                "h:mm A"
              )}`}
            </Typography>
            <Box className="card-airline-container">
              <AirlineIcon airlineCode={firstTripSegment.airlineCode} />
              <Typography variant="body2">
                {firstTripSegment.airlineName}
                {Boolean(airlinesCount) && (
                  <span className="vi-more red-text">{`+ ${airlinesCount} other`}</span>
                )}
              </Typography>
            </Box>
          </Box>
          <Box className="airline-details-right-container">
            <Typography variant="body1">
              {formatInterval(
                dayjs(tripSlice.arrivalTime).diff(
                  dayjs(tripSlice.departureTime),
                  "minute",
                  true
                )
              )}
            </Typography>
            <Typography variant="body2">
              {constants.getStopsString(tripSlice.stops)}
            </Typography>
          </Box>
        </Box>
        <Button
          className="review-itinerary-change-button b2b"
          onClick={() => {
            setOpenChangeFlightModal
              ? setOpenChangeFlightModal(true)
              : setPackagesFlightShopProgress(FlightShopStep.ChooseReturn);
            history.goBack();
          }}
        >
          {constants.CHANGE_FLIGHT}
        </Button>
      </Box>
      <FontAwesomeIcon className="mobile-right-chevron" icon={faChevronRight} />
    </Box>
  );
};
