import { connect, ConnectedProps } from "react-redux";

import { MobileCalendarPickerModal } from "./component";
import { withRouter } from "react-router";
import { actions } from "../../../search/actions";
import { IStoreState } from "../../../../reducers/types";
import {
  getDepartureDate,
  getReturnDate,
  getDestination,
  getTravelers,
  getOrigin,
  getStopsOption,
} from "../../../search/reducer";

const mapDispatchToProps = {
  setDepartureDate: actions.setDepartureDate,
  setReturnDate: actions.setReturnDate,
};
const mapStateToProps = (state: IStoreState) => {
  return {
    departureDate: getDepartureDate(state),
    returnDate: getReturnDate(state),
    searchLocation: getDestination(state),
    travelers: getTravelers(state),
    origin: getOrigin(state),
    stopsOptions: getStopsOption(state),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type MobileCalendarPickerModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedMobileCalendarPickerModal = withRouter(
  connector(MobileCalendarPickerModal)
);
