import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { PackageDetailsPanel } from "./component";
import { IStoreState } from "../../../../reducers/types";

export const mapStateToProps = (_: IStoreState) => {
  return {};
};

export const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PackagesDetailsPanelConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackageDetailsPanel = withRouter(
  connector(PackageDetailsPanel)
);
