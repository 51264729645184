import React from "react";
import { Box, Typography } from "@material-ui/core";
import { MobileCalendarPickerConnectorProps } from "./container";
import * as textConstants from "./textConstants";
import "./styles.scss";
import clsx from "clsx";
import { Icon, IconName, PickerType } from "halifax";
import dayjs from "dayjs";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
  PackagesSearchButton,
} from "../../../PackagesSearchControl/components";

export interface IMobileCalendarPickerProps
  extends MobileCalendarPickerConnectorProps {
  departure: Date | null;
  returnDate: Date | null;
  focusedMonthIndex: number;
  onComplete?: () => void;
  minAllowedDate?: Date;
  maxAllowedDate?: Date;
  onSelectDates?: (departure: Date | null, returnDate: Date | null) => void;
}

export const MobileCalendarPicker = (props: IMobileCalendarPickerProps) => {
  const {
    readyToSearch,
    focusedMonthIndex,
    onComplete,
    setDepartureDate,
    setReturnDate,
    departure,
    returnDate,
    minAllowedDate,
    maxAllowedDate,
    onSelectDates,
  } = props;

  const incrementPackagesFunnelSearchesCounter = () => { 
    const PACKAGES_FUNNEL_SEARCHES = "packages-funnel-searches";
    const searches = parseInt(sessionStorage.getItem(PACKAGES_FUNNEL_SEARCHES) || "0")
    sessionStorage.setItem(PACKAGES_FUNNEL_SEARCHES, String(searches + 1));
  }

  const DatePickerHeader = ({
    label,
    value,
  }: {
    label: string;
    value?: string;
  }) => {
    return (
      <Box className="selected-date-wrapper">
        <Icon name={IconName.Calendar} />
        <Box className="selected-date-content">
          <Typography className="content-top-label">
            {value ? label : ""}
          </Typography>
          <Typography
            className={clsx("content-main-content", { label: !value })}
          >
            {value || label}
          </Typography>
        </Box>
      </Box>
    );
  };

  return (
    <Box className="mobile-packages-calendar-picker-root">
      <MonthAndDatePicker
        className="b2b-packages"
        pickerType={PickerType.RANGE}
        viewType={MonthAndDatePickerType.Column}
        focusedMonthIndex={focusedMonthIndex}
        setStartDate={setDepartureDate}
        setEndDate={setReturnDate}
        startDate={departure}
        endDate={returnDate}
        minAllowedDate={minAllowedDate}
        maxAllowedDate={maxAllowedDate}
        header={
          <Box className="packages-mobile-calendar-header">
            <DatePickerHeader
              label={textConstants.OUTBOUND}
              value={
                departure ? dayjs(departure).format("ddd, MMM D") : undefined
              }
            />

            <DatePickerHeader
              label={textConstants.RETURN}
              value={
                returnDate ? dayjs(returnDate).format("ddd, MMM D") : undefined
              }
            />
          </Box>
        }
      />
      {readyToSearch && (
        <Box className="mobile-packages-search-button-container">
          <PackagesSearchButton
            className={clsx("mobile-calendar-picker-search-button", "b2b")}
            message={textConstants.SEARCH_BUTTON_TEXT}
            onClick={() => {
              onComplete && onComplete();
              onSelectDates?.(departure, returnDate);
              incrementPackagesFunnelSearchesCounter();
            }}
          />
        </Box>
      )}
    </Box>
  );
};
