export const CHANGE_HOTEL_BODY =
  "If you choose to change your hotel, you will be required to reselect your flights.";
export const CHANGE_ROOM_BODY =
  "If you choose to change your room, you will be required to reselect your flights.";
export const CHANGE_FLIGHT_BODY =
  "If you choose to change your outbound flight, you will be required to reselect your return flight.";

export const getContinueCTAText = (
  ctaType: "hotel" | "room" | "outbound-flight",
  isMobile?: boolean
) => {
  let ctaText = "Continue";
  if (isMobile) {
    switch (ctaType) {
      case "hotel":
        ctaText += ` to change hotel`;
        break;
      case "room":
        ctaText += ` to change rooms`;
        break;
      case "outbound-flight":
        ctaText += ` to change flight`;
        break;
    }
  }
  return ctaText;
};

export const CANCEL = "Cancel";
export const CLOSE = "Close";
