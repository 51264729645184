import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { PackagesContactInfoForm } from "./component";
import { IStoreState } from "../../../../reducers/types";

const mapStateToProps = (_: IStoreState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type PackagesContactInfoFormConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedPackagesContactInfoForm = withRouter(
  connector(PackagesContactInfoForm)
);
