import { Box } from "@material-ui/core";
import React, { useState } from "react";
import { FlightList } from "../FlightList";
import "./styles.scss";
import { RewardsAccountSelection } from "../../../rewards/components";
import { PackagesShopProgressBar } from "../../../book/components";
import { FloatingMenuPill, IconName, BackToTopButton } from "halifax";
import { IMobileFlightShopProps } from "./container";
import { FlightShopAllFiltersModal } from "../FlightShopAllFiltersModal";
import { MobileShopHeader } from "../MobileShopHeader";

export const MobileFlightShop = (props: IMobileFlightShopProps) => {
  const { activeFiltersCount } = props;
  const [allFiltersModalOpen, setAllFiltersModalOpen] = useState(false);

  return (
    <Box className={"mobile-package-flight-shop-root"}>
      <MobileShopHeader />
      <PackagesShopProgressBar isMobile />
      <Box className="mobile-hotel-shop-rewards-account-contents">
        <RewardsAccountSelection className="b2b" popoverClassName="b2b" />
      </Box>
      <FlightList />
      <>
        <FloatingMenuPill
          items={[
            {
              label: "Sort & filter",
              onClick: () => setAllFiltersModalOpen(true),
              icon: IconName.Settings,
              badge: activeFiltersCount
                ? String(activeFiltersCount)
                : undefined,
            },
          ]}
        />
        <FlightShopAllFiltersModal
          openAllFiltersModal={allFiltersModalOpen}
          setOpenAllFiltersModal={setAllFiltersModalOpen}
          isMobile
        />
        <BackToTopButton />
      </>
    </Box>
  );
};
